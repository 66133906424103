$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.stockRightTable {
    width: 100%;
    height: 100%;

    .typeBox {
        height: 34px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .anticon {
            font-size: 20px;
            cursor: pointer;
        }
    }

    .stockRightTableBox {
        width: 100%;
        margin-top: 10px;
        height: calc(100% - 45px);
        border-radius: 10px;
        border: 1px solid rgba(0, 104, 178, 0.1);
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        .stockRightTableHeader {
            height: 60px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;

            .headerCell {
                flex: none;
                height: 100%;
                width: 25%;
                z-index: 10;
                box-sizing: border-box;
                background: rgba(238, 245, 251);
                border: 0.5px solid rgba(0, 104, 178, 0.1);
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #535353;
                text-align: center;
                line-height: 60px;
            }
        }

        .stockRightTableContent {

            // height: 1400px;
            // width: 2500px;
            // // background: red;
            box-sizing: border-box;
            margin-top: 60px;
            display: flex;
            height: calc(100% - 60px);
            width: 100%;
            overflow: auto;

            @include scrollbar;

            .column {
                width: 25%;
                box-sizing: border-box;

                .cell {
                    width: 100%;
                    border: 0.5px solid rgba(0, 104, 178, 0.1);
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    text-align: center;
                    @include flex-c-c;

                    .cellBox {
                        padding: 20px 10px;
                        width: 100%;
                        .titleBox {
                            padding-left: 5px;
                            text-align: left;

                            .iconSvg {
                                margin-left: 5px;
                                margin-right: 0;
                                transform: translateY(2px);
                            }

                            .editIcon {
                                font-size: 14px;
                                margin-left: 5px;
                            }
                        }

                        .tagBox {
                            // @include flex-c-c;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;

                            .ant-tag {
                                line-height: 16px;
                                transform: scale(0.85);
                                margin: 0;
                            }

                            .percent {
                                height: 18px;
                                border-radius: 4px 4px 4px 4px;
                                opacity: 1;
                                border: 1px solid #6CAEFF;
                                background: #ffffff;
                                font-weight: 400;
                                color: #6CAEFF;
                            }

                            .ultimateBenefit {
                                border-radius: 4px 4px 4px 4px;
                                opacity: 1;
                                border: 1px solid #0068B2;
                                background: #ffffff;
                                font-weight: 400;
                                color: #0068B2;
                            }

                            .controller {
                                border-radius: 4px 4px 4px 4px;
                                opacity: 1;
                                border: 1px solid #6CAEFF;
                                background: #6CAEFF;
                                font-weight: 400;
                                color: #fff;
                            }

                            .cpercent {
                                border-radius: 4px 4px 4px 4px;
                                opacity: 1;
                                border: 1px solid #FE545F;
                                background: #FE545F;
                                font-weight: 400;
                                color: #fff;
                            }

                        }
                    }
                }
            }
        }
    }
}