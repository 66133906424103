$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.projectInfoContent {
    @mixin bg-br {
        background: #fff;
        border-radius: 10px;
    }

    height: 100%;
    width: 100%;
    @include flex;
    @include flex-j-s-b;

    .myCardContent {
        padding: 8px 15px 0 15px;
    }

    .leftBox {
        width: calc(70% - 20px);
        height: 100%;

        .topBox {
            @include flex-c-c;
            width: 100%;
            height: 230px;
            margin-bottom: 20px;
        }

        .leftTop {
            height: 100%;
            @include bg-br;
            padding: $mainPadding;
            padding-bottom: 0;

            .scrollBox {
                width: 100%;
                height: 100%;
                @include flex;
                @include flex-j-s-b;

                .projectInfoBox {
                    // width: 70%;
                    width: 100%;
                }

                .chart {
                    // width: 30%;
                    // height: 100%;
                }
            }


        }

        .relevantProjectBox {
            height: 100%;
            width: 34%;
            padding: $mainPadding;
            padding-right: 10px;
            @include bg-br;

            .relevantProjectContent {
                padding-right: 10px;

                .relevantProjectItem {
                    width: 100%;
                    @include flex-c-c;
                    flex-wrap: wrap;
                    margin-bottom: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .relevantProjectTitle {
                        width: 100%;
                        font-size: 14px;
                        font-weight: normal;
                        color: #000000;
                        cursor: pointer
                    }

                    .relevantProjectTitle:hover {
                        color: #0068B2;
                    }

                    .valueItem {
                        @include flex;
                        align-items: center;
                        margin-top: 8px;
                        flex-wrap: wrap;

                        .label {
                            width: 50px;
                            display: inline-block;
                            text-align: justify;
                            text-align-last: justify;
                            font-size: 12px;
                            font-weight: normal;
                            color: #9E9E9E;
                        }

                        .value {
                            width: calc(100% - 55px);
                            margin-left: 5px;
                            font-size: 12px;
                            font-weight: normal;
                            color: #535353;

                            .ant-typography {
                                margin-bottom: 0px;
                            }
                        }
                    }

                }
            }
        }

        .leftBottom {
            height: calc(100% - 250px);
            width: 100%;
            @include bg-br;
            padding: $mainPadding;

            .leftBottomContent {
                width: 100%;
                height: calc(100% - 20px);
                @include flex;

                .mapBox {
                    width: 60%;
                    height: 100%;
                    // background: red;
                    @include flex-c-c;

                    .map {
                        width: 100%;
                        position: relative;
                        overflow: hidden;

                        .mapBg {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: url('../../../assets/圈.svg') no-repeat;
                            background-size: 100% 100%;
                            background-position-x: -2px;
                            background-position-y: -1px;
                            animation: roate 30s infinite linear;
                            z-index: 1;
                        }

                        .iconBox {
                            svg {
                                height: var(--icon-width);
                                width: var(--icon-width);
                            }

                        }

                        // background: pink;
                    }
                }

                .menuInfoBox {
                    width: 40%;
                    height: 100%;
                    @include flex-c-c;
                    flex-wrap: wrap;
                    padding: 0px 20px;
                    overflow-y: auto;

                    @include scrollbar;

                    .comContent {
                        min-height: 50%;
                        width: 100%;
                        max-height: 100%;
                        overflow: auto;
                        @include scrollbar;

                        .comTitle {
                            font-size: 14px;
                            font-family: PingFang SC-粗体, PingFang SC;
                            font-weight: bold;
                            color: #0068B2;
                            position: relative;

                            .line {
                                position: absolute;
                                width: 16px;
                                height: 2px;
                                bottom: -8px;
                                left: 20px;
                                background-color: #0068B2;
                            }
                        }

                        .comInfo {
                            margin-top: 16px;
                            font-size: 12px;
                            font-family: PingFang SC-常规, PingFang SC;
                            font-weight: normal;
                            color: #535353;
                        }
                    }

                    .childrenBox {
                        width: 100%;
                    }

                    .childrenItem {
                        width: 100%;
                        display: flex;

                        .iconBox {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                            border-radius: 50%;
                            text-align: center;
                            // font-size: 1rem;
                            @include flex-c-c;
                            flex: none;

                            div {
                                @include flex-c-c;
                            }

                            svg {
                                width: 12px;
                                height: 12px;
                            }
                        }

                        .content {
                            width: calc(100% - 24px);
                            margin-top: 5px;

                            .title {
                                font-size: 14px;
                                font-family: PingFang SC-粗体, PingFang SC;
                                font-weight: bold;
                                color: #222222;
                                line-height: 14px;
                                // display: flex;
                                cursor: pointer;
                                user-select: none;

                                &:hover {
                                    color: #0068B2;
                                }
                            }

                            .childrenContent {
                                padding: 10px 0 0 0;
                                font-size: 12px;
                                font-weight: 400;
                                color: #535353;
                                border-bottom: 1px dashed #D8D8D8;
                                margin-bottom: 15px;
                            }

                        }
                    }

                    .childrenItem:last-of-type {
                        .childrenContent {
                            border-bottom: none;
                            margin-bottom: 0px;
                        }
                    }
                }
            }

        }
    }

    .rightBox {
        width: 30%;
        height: 100%;

        .rightTop {
            height: calc(45% - 10px);
            width: 100%;
            // margin-bottom: 20px;
            @include bg-br;
            padding: $mainPadding 0;
        }

        .line {
            height: 10px;
            @include flex-c-c;
            user-select: none;
            margin: 5px 0;

            .moveBox {
                width: 40px;
                background: #53535364;
                color: #D8D8D8;
                text-align: center;
                height: 6px;
                border-radius: 5px;

                &:hover {
                    background: #535353;
                }
            }

            .activeMove {
                background: #535353;
            }
        }

        .rightBottom {
            height: calc(55% - 10px);
            width: 100%;
            @include bg-br;
            // padding: $mainPadding;
        }
    }
}

@keyframes roate {
    0% {
        transform: rotateZ(0);
        -ms-transform: rotateZ(0);
        -moz-transform: rotateZ(0);
        -webkit-transform: rotateZ(0);
        -o-transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(360deg);
        -ms-transform: rotateZ(360deg);
        -moz-transform: rotateZ(360deg);
        -webkit-transform: rotateZ(360deg);
        -o-transform: rotateZ(360deg);
    }
}