$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.RecordDetailsContent {
    width: 100%;
    height: 100%;
    background: #fff;

    .headerTitleContent {
        margin: 20px;
    }

    .tableCardBoxContent {
        width: 100%;
        height: calc(100% - 70px);
        // padding: 0 20px;
        &>div{
            &>div:nth-of-type(3){
                z-index: 99;
            }
        }
    }

    .tabBox {
        height: calc(100% - 10px);
        width: calc(100% - 40px);
        margin: 0px 20px 20px;

        .tableContentBox {
            // max-height: 400px;
            padding: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;

            .noData {
                width: 100%;
                height: 100%;
                @include flex-c-c;
            }

            .failRow {
                .ant-table-cell {
                    color: red;
                }
            }

            .paginationBox {
                margin-top: 13px;
                display: flex;
                flex-direction: row-reverse;
            }
        }

        .dateBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            padding-right: 5px;

            .dateAndSearch {
                display: flex;

                .ant-select {
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                    box-sizing: border-box;
                }
            }

            .searchBox {
                margin-left: 20px;

                .ant-select {
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                    box-sizing: border-box;
                }

                .ant-select-selection-overflow {
                    flex-direction: row-reverse
                }

                .ant-select-multiple .ant-select-selection-placeholder {
                    text-align: right;
                    padding-right: 20px;
                }
            }

            .dateContent {
                font-size: 14px;
                font-weight: normal;
                color: #9E9E9E;
                @include flex-c-c;
            }

            .icons {
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                user-select: none;

                .anticon {
                    margin-left: 5px
                }
            }
        }

        .comBox {
            .item {
                font-size: 14px;
                font-family: PingFang SC-中等, PingFang SC;
                font-weight: normal;
            }

            .ant-tag {
                margin: 0px;
                font-size: 12px;
                font-family: Arial-Regular, Arial;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 18px;
                padding: 0 4px;
                max-width: 100%;
                word-wrap: break-word;
                word-break: normal;
                white-space: pre-wrap;
                transform: scale(0.8);
            }

            .iconItems {
                color: #9E9EE9;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 10px;

                .anticon:first-of-type {
                    margin-bottom: -1px;
                }

                .anticon:last-of-type {
                    margin-top: -1px;
                }

            }
        }
    }

    .iconBox {
        @include flex-c-c;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        padding-right: 5px;

        .clearAll {
            font-size: 12px;
            margin-right: 10px;

            &:hover {
                color: #0068B2;
            }
        }

        // .anticon {
        //     margin-right: 5px
        // }
    }

    .pathCell {
        padding: 10px 0px !important;
    }

    .addItem {
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
            font-size: 16px;
            margin-right: 5px;
            cursor: pointer;
        }

        .anticon:hover {
            color: #0068B2;
        }

        .iconSvg {
            height: 16px;
            width: 16px;

            svg,
            div {
                height: 16px;
                width: 16px;
            }
        }

    }

    .pathCollapse {
        .pathLineBox {
            display: flex;
            align-items: center;
            padding: 0 10px;

            .pathLineTitle {
                flex: none;
                font-weight: bold;
                color: #313131;
                font-size: 12px;
            }
        }

        .pathMoreDiv {
            cursor: pointer;
            font-size: 12px;
            color: #0068B2;
            display: flex;
            align-items: center;
            justify-content: right;
            padding-right: 20px;

            .anticon {
                margin-left: 2px;
                font-size: 10px;
            }
        }
    }

    .ripTagBox {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 14px;
        height: 14px;
        background: #FEC054;
        color: #fff;
        border-radius: 2px 2px 2px 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 14px;
            height: 14px;
        }
    }
}

.chartModal {
    .content {
        width: 100%;
        height: 80vh;
    }
}

.filterCheckBox {
    max-height: 300px;
    overflow-y: auto;
    @include scrollbar;
    width: 100%;

    .ant-checkbox-wrapper {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        white-space: nowrap;
    }
}