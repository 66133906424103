$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondAddPageContent {
    @mixin bg-br {
        background: #fff;
        border-radius: 10px;
    }

    height: 100%;
    width: 100%;
    @include flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // @include flex-j-s-b;
    @include bg-br;
    padding: 20px;

    .bondAddPageBox {
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .leftBondAddPageContent {
        width: calc(75% - 20px);
        height: 100%;
        margin-right: 20px;

        .tipBox {
            font-weight: bold;
            font-size: 16px;
            color: #313131;
        }

        .searchBox {
            margin-top: 10px;
            margin-bottom: 10px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .searchItemBox {
                display: flex;
                align-items: center;

                .searchLabel {
                    margin-left: 24px;
                    margin-right: 4px;
                    flex: none;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }

                .ant-select-auto-complete {
                    .ant-select-clear {
                        right: -2px;
                    }
                }

                .ant-select-selection-search-input {
                    text-overflow: ellipsis;
                }

                .ant-select-selection-search-input {
                    height: 25px;
                    line-height: 25px;
                }

                .ant-select-selection-placeholder {
                    line-height: 25px;
                }

                .ant-select-selector {
                    height: 25px;
                    line-height: 25px;
                }

                .ant-select-selection-item {
                    line-height: 25px;
                }
            }

            .addAllBox {
                color: #0068B2;
                cursor: pointer;
                user-select: none;
                flex: none;
                margin-left: 4px;
                // &:hover {
                //     color: #0068B2;
                // }
            }
        }

        .bondAddPageInfiniteScrollBox {
            height: calc(100% - 76px);
            width: 100%;
            overflow: auto;
            @include scrollbar;

            .cardBox {
                // padding: 0 20px;
                // height: 100%;
                display: flex;
                // align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                .cardItem {
                    width: calc(50% - 5px);
                    min-height: 100px;
                    background: #FFFFFF;
                    border-radius: 8px 8px 8px 8px;

                    // border: 1px solid #D8D8D8;
                    // margin-bottom: 20px;
                    &:nth-last-of-type(1),
                    &:nth-last-of-type(2) {
                        .myCardContent {
                            margin-bottom: 0 !important;
                        }
                    }

                    .label {
                        width: 60px !important;
                    }

                    .value {
                        width: calc(100% - 65px) !important;
                    }

                    .valueItem {
                        &:nth-of-type(2) {
                            .label {
                                width: 30px !important;
                            }

                            .value {
                                width: calc(100% - 35px) !important;
                            }
                        }
                    }

                    .ant-typography {
                        margin-bottom: 0;
                    }

                    .addIcon {
                        cursor: pointer;
                        color: #0068B2;
                    }

                    .disAddIcon {
                        cursor: not-allowed;
                        color: #9E9E9E
                    }
                }
            }

            .paginationBox {
                margin-top: 13px;
                padding-right: 10px;
                display: flex;
                flex-direction: row-reverse;
            }
        }

    }

    .rightBondAddPageContent {
        width: 25%;
        height: 100%;
        box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 8px 8px;
        padding: 20px 0;

        .tipBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            font-size: 16px;
            color: #313131;
            padding: 0 20px;

            .anticon {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;

                &:hover {
                    color: #0068B2;
                }
            }

            .number {
                font-size: 20px;
                margin: 0 4px;
                color: #0068B2;
            }
        }

        .IssuerListBox {
            width: 100%;
            padding: 0 20px;

            .IssuerCard {
                width: 100%;
                // height: 100px;
                border-bottom: 1px dashed #D8D8D8;
                padding: 20px 0;

                .companyNameBox {
                    font-weight: bold;
                    font-size: 14px;
                    color: #313131;
                    cursor: pointer;

                    &:hover {
                        color: #0068B2;
                    }
                }

                .companyInfoBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #9E9E9E;
                    font-size: 12px;
                    margin-top: 16px;

                    .infoSpan {
                        width: calc(100% - 24px);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #535353;

                        &>div {
                            display: flex;
                            flex-wrap: nowrap;

                            &:first-of-type {
                                width: calc(100% - 85px)
                            }

                            &:last-of-type {
                                min-width: 75px;
                                margin-left: 10px;
                            }
                        }

                        span {
                            color: #9E9E9E;
                            margin-right: 4px;
                            flex: none;
                        }
                    }

                    .deleteIcon {
                        cursor: pointer;
                        width: 14px;
                        height: 14px;

                        div {
                            width: 14px;
                            height: 14px;

                            svg {
                                height: 12px;
                                width: 12px;
                            }
                        }

                        &:hover {
                            color: #0068B2;
                        }
                    }
                }
            }
        }

    }

    .emptyBox {
        height: 100%;
        @include flex-c-c;
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:active,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:hover {
        color: rgba(0, 0, 0, .25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }
}

.bondAddPageParagraphTooltip {
    max-width: 450px;

    .bondAddPageParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}