$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.pieMenuContent {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
    z-index: 99;
    #peiMenu {

        .paths {
            transition: transform .2s, background-color .6s, fill .6s;
            color: white;
            cursor: pointer;
        }

        .active {
            .iconBox {
                background-color: #fff !important;
                color: #0068B2;
                div{
                    @include flex-c-c;
                }
                // svg{
                //     width: 24px;
                //     height: 24px;
                // }
                path{
                    fill: #0068B2;
                    
                }
            }
        }

        .noAction {
            color: white;
            cursor: pointer;
            .iconBox {
                div{
                    @include flex-c-c;
                }
                // svg{
                //     width: 24px;
                //     height: 24px;
                // }
                path {
                    color: white;
                    
                }
            }
        }

        .list {
            transition: transform .2s, background-color .6s, fill .6s;
            cursor: pointer;
            padding: 17.5px;
            border-radius: 50%;
            display: flex;
            flex-direction: column-reverse;

            .box {
                width: 100%;
                height: 100%;
                @include flex-c-c;
                flex-direction: column;
                position: relative;
            }

            .iconBox {
                border-radius: 50%;
                text-align: center;
                // font-size: 1rem;
                @include flex-c-c;
                flex: none;
            }

        }

        .text {
            cursor: pointer;
            div {
                cursor: pointer;
                text-align: center;
                color: #EDF6FC;
                z-index: 100 !important;
                user-select: none;
                margin-top: 10px;
            }
        }
    }
}