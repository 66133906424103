$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.billDetailsPopulationContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        position: relative;
        z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }
    }

    .emptyContent {
        width: 100%;
        height: 254px;
        @include flex-c-c;
    }

    .billDetailsPopulationBox {
        display: flex;
        width: 100%;
        height: 320px;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        position: relative;

        .billTypeBox {
            height: 20px;
            width: 100px;
            position: absolute;
            top: 0;
            right: calc(50% - 50px);
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #313131;
                font-size: 12px;
                i {
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #61D4AD;
                    margin-right: 2px;
                }

                &:first-of-type {
                    margin-right: 5px;
                    i {
                        background-color: #0068B2;

                    }
                }
            }
        }

        .billCountBox {
            width: calc(50% - 20px);
            height: 100%;
        }

        .billAmountBox {
            width: calc(50% - 20px);
            height: 100%;
        }

        .titleBox {
            font-size: 18px;
            width: 100%;
            text-align: center;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #0068B2;
            // margin-bottom: 16px;
        }



        #billDetailsPopulationChartBox1,
        #billDetailsPopulationChartBox2 {
            position: relative;
            height: calc(100% - 46px);
            width: 100%;
            z-index: 8;

            // background: #313131;
            ~div,
            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .billDetailsPopulationTipItem {
        width: 100%;
        display: flex;
        // align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #535353;
        margin-top: 8px;

        i {
            display: inline-block;
            width: 5px;
            height: 5px;
            background: #D8D8D8;
            border-radius: 50%;
            margin-right: 6px;
            margin-top: 7px;
        }

        .numberSpan {
            color: #003666;
            font-weight: bold;
        }
    }

}