$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondCardContent {
    width: 100%;
    height: 110px;
    padding: 20px 0;
    border-bottom: 1px solid #D8D8D8;
    display: flex;
    align-items: center;

    &:last-of-type {
        border: none;
    }

    .ant-typography {
        margin-bottom: 0;
    }

    .cardInfoBox {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cardBox1 {
            height: 100%;
            width: calc(85% - 370px);
            min-width: 370px;
            display: flex;
            align-items: center
        }

        .cardBox2 {
            flex: none;
            width: 90px;
            height: 100%;
            display: flex;
            align-items: center
        }

        .iconBox {
            @include flex;

            .infoImg {
                display: block;
                width: 66px;
                height: 66px;
                border-radius: 4px;
                cursor: pointer;
                user-select: none;

                &:hover {
                    filter: brightness(105%);
                }
            }


            .hoverPic {
                user-select: none;
                cursor: pointer;
                color: #0068B2;
                border-radius: 4px;
                background-color: #F4F8FB;

                &:hover {
                    background: rgba($color: #0068B2, $alpha: 0.1);
                    color: #0068B2;
                }
            }
        }

        .infoBox {
            width: calc(100% - 66px);
            height: 100%;
            margin-left: 10px;

            .titleItem {
                font-weight: bold;
                font-size: 16px;
                color: #0068B2;
                margin-bottom: 5px;

                .ant-typography {
                    cursor: pointer;

                    &:hover {
                        color: #0068B2 !important;
                    }
                }

            }

            .itemBox {
                @include flex;
                flex-wrap: wrap;

                .valueItem {
                    @include flex;
                    margin-top: 2px;
                    flex-wrap: wrap;



                    .label {
                        width: 70px;
                        display: inline-block;
                        text-align: justify;
                        text-align-last: justify;
                        font-size: 12px;
                        font-weight: normal;
                        color: #9E9E9E;
                    }

                    .value {
                        width: calc(100% - 75px);
                        margin-left: 5px;
                        font-size: 12px;
                        font-weight: normal;
                        color: #535353;
                    }

                    &:nth-of-type(2) {
                        .label {
                            width: 30px;
                        }

                        .value {
                            width: calc(100% - 35px);
                        }
                    }
                }
            }
        }

        .numberBox {
            width: 130px;
            flex: none;
            height: 100%;
            margin-left: 10px;
            font-size: 12px;
            color: #535353;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .numberDiv {
                span {
                    font-weight: bold;
                    font-size: 20px;
                    color: #0068B2;
                }

                font-weight: 400;
                font-size: 12px;
                color: #535353;
            }
        }

        .lineBarBox {
            width: 150px;
            flex: none;
            height: 100%;
            margin-left: 10px;

            .lineBar {
                width: 100%;
                height: 100%;
            }

            .noDataChartBox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                // background: #F4F8FB;
                font-size: 12px;
                color: rgba(0, 0, 0, .3);

                img {
                    user-select: none;
                }
            }
        }

        .circleBarBox {
            width: 70px;
            height: 100%;
            margin-left: 10px;

            .circleBar {
                width: 100%;
                height: 100%;
            }

            .noDataChartBox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                // background: #F4F8FB;
                font-size: 12px;
                color: rgba(0, 0, 0, .3);

                img {
                    user-select: none;
                }
            }
        }

        .deleteIconBox {
            width: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .deleteIcon {
                display: none;
                width: 17px;
                height: 17px;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17px;
                    height: 17px;
                }

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            &:hover {
                color: #0068B2;
            }
        }

        &:hover {
            .deleteIconBox {
                .deleteIcon {
                    display: block;
                }
            }
        }
    }

}

.bondAddPageParagraphTooltip {
    max-width: 450px;

    .bondAddPageParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}