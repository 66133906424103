$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.charts {
    width: 100%;
    padding: 5px;
    height: calc(100% - 2px);
    border-radius: 10px;
    border: 1px solid rgba(0, 104, 178, 0.1);
    position: relative;
    background: #fff;

    .firstLoadingBox {
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }

    .tipBox {
        position: fixed;
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
        background-color: #fff;
        background-clip: padding-box;
        // padding-top: 5px;
        // padding-left: 5px;
        width: 300px;
        max-height: 400px;
        // padding: 12px 16px;
        color: rgba(0, 0, 0, .85);
    }

    .groupShowBox {
        position: absolute;
        background: #fff;
        top: 40px;
        right: 12px;
        width: 18px;
        font-size: 12px;
        line-height: 14px;
        color: #0068B2;
        text-align: center;
        user-select: none;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px;
            height: 14px;
            opacity: 1;
            border: 1px solid #313131;
            border-radius: 50%;
            font-size: 12px;
            font-family: Arial-Narrow Bold, Arial;
            font-weight: bold;
            color: #313131;
            cursor: pointer;
        }

        .activeSpan {
            color: #0068B2;
            border: 1px solid #0068B2
        }

        .ant-dropdown-trigger {
            cursor: pointer;
        }
    }

    #downloadPicCanvasBox {
        // display: none;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -9999;
        width: 100%;
        height: 100%;
    }

    .chartHandleBox {
        position: absolute;
        top: 20px;
        right: 20px;
        // width: 280px;
        @include flex-c-c;

        .openBox {
            width: 200px;
        }

        .downIcon {
            font-size: 20px;
            font-weight: 100;
        }

        .allIcon {
            margin: 0 10px 0 5px;

            div {
                @include flex-c-c;
            }

            svg {
                cursor: pointer;
                height: 20px;
                width: 20px;

                &:hover {
                    color: #006AB2;
                }
            }
        }
    }

    .rePenetrationBtnBox {
        .ant-popover {
            height: 22px;
            z-index: 11;
        }

        .ant-popover-arrow {
            width: 11px;
            height: 14px;
            left: 4px;
        }

        .ant-popover-inner-content {
            padding: 0;
            background: #FE545F;
            color: #fff;
            border-radius: 4px;
        }

        .ant-popover-arrow-content {
            background: #FE545F;
            --antd-arrow-background-color: #FE545F;
        }

        .editText {
            width: 160px;
            font-size: 10px;
            font-family: Arial-Regular, Arial;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 2px;
            border-radius: 4px;
        }
    }
}

.chartsModal {
    .ant-modal {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 20px;
        }

        .ant-modal-close-x {
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 20px;
            padding-bottom: 0;
        }

        #detailHtmlBox {
            padding: 20px;
            max-height: 80vh;
            overflow: auto;
            @include scrollbar;
        }
    }

    .formContent {
        .ant-form-item {
            .ant-form-item-label {
                width: 80px;
            }

        }
    }
}

.dropdownItemA {
    border-radius: 5px;
    box-shadow: none;
    z-index: 99;
    width: 170px;

    .ant-dropdown-menu {
        box-shadow: none;
        border: 1px solid rgba(0, 104, 178, 0.1000);
        padding: 0;

        .ant-dropdown-menu-item {
            padding: 3px 5px;
            color: #313131;
            text-align: right;
        }

        .ant-dropdown-menu-item:hover {
            background: rgba(0, 104, 178, 0.0500) !important;
            color: #0068B2
        }

    }
}

.remarkTip {
    .ant-popover-inner-content {
        padding: 0 !important;
    }

    .commentsContent {
        padding: 12px 16px;
    }
}

.rulesTooltip {
    width: 190px;

    .ruleButtonBox {
        width: 100%;
        @include flex-c-c;
        margin-top: 8px;
    }

    .ruleButton {
        width: 138px;
        height: 32px;
        background: #16D8D8;
        border-color: #16D8D8;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
}

.filterDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px
    }

    .formContent {
        padding: 20px;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        @include scrollbar;

        .formItemBox {
            width: 100%;

            .formInputItemBox {
                width: 100%;
                display: flex;
                margin: 12px 0;
                align-items: center;

                .separateSpan {
                    margin: 0 24px 0 12px;
                }

                .unitBox {
                    width: 30px;
                }
            }
        }
    }

    .drawerFooterBtnBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        .btnBox11 {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}

.concertedActionPersonGroupModal {
    .ant-modal {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 20px;
        }

        .ant-modal-close-x {
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 20px;
            // padding-bottom: 0;
        }

        #detailHtmlBox {
            padding: 20px;
            max-height: 80vh;
            overflow: auto;
            @include scrollbar;
        }
    }

    .concertedActionPersonGroupBox {
        width: 100%;

        .concertedActionPersonGroupTitleBox {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .concertedActionPersonGroupTitle {
                font-size: 16px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #0068B2;
                display: flex;
                align-items: center;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 6px;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    border: 1px solid #313131;
                    border-radius: 50%;
                    font-size: 12px;
                    font-family: Arial-Narrow Bold, Arial;
                    font-weight: 700;
                    color: #313131;
                    cursor: pointer;
                    color: #0068B2;
                    border: 1px solid #0068B2
                }
            }

            .concertedActionPersonGroupInputBox {
                display: flex;
                align-items: center;

                .ant-select-selector {
                    height: 28px;
                    border-bottom: 1px solid #d9d9d9 !important;
                }
            }

            .groupBox {
                max-width: 80px;
                height: 20px;
                display: flex;
                align-items: center;
                margin-left: 8px;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    border: 1px solid #313131;
                    border-radius: 50%;
                    font-size: 12px;
                    font-family: Arial-Narrow Bold, Arial;
                    font-weight: 700;
                    color: #313131;
                    cursor: pointer;
                }

                .activeSpan {
                    color: #0068B2;
                    border: 1px solid #0068B2
                }
            }
        }

        .remarksBox {
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: bold;
            color: #535353;
            display: flex;
            align-items: center;
            margin-top: 6px;

            .remarkContent {
                width: calc(100% - 36px);
                margin-left: 6px;
                font-weight: 400;

                .ant-typography {
                    margin-bottom: 0;
                }
            }

            .ant-input {
                border-bottom: 1px solid #d9d9d9 !important;
                padding: 2px;
                margin-left: 6px;
            }
        }

        .concertedActionPersonGroupListBox {
            width: 100%;
            margin-top: 12px;

            .titleBox {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #313131;
            }

            .concertedActionPersonGroupListContent {
                margin-top: 12px;
                margin-bottom: 12px;
                width: 100%;
                height: 280px;
                overflow-y: auto;
                @include scrollbar;

                .emptyContent {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .concertedActionPersonItem {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 3px 3px 3px 0;

                    span {
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #0068B2;
                    }

                    .anticon {
                        cursor: pointer;
                        color: #D8D8D8;
                    }

                    &:hover {
                        background: rgba(0, 104, 178, 0.1);

                        .anticon {
                            color: #0068B2;
                        }
                    }
                }
            }
        }

        .concertedActionPersonGroupBtnBox {
            width: 100%;
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-dropdown-trigger {
            cursor: pointer;
        }
    }
}

.dropdownItemB {
    border-radius: 5px;
    box-shadow: none;
    width: 30px;
    cursor: pointer;

    .ant-dropdown-menu {
        box-shadow: none;
        border: 1px solid rgba(0, 104, 178, 0.1000);
        padding: 0;

        .ant-dropdown-menu-item {
            padding: 3px 5px;
            color: #313131;
            text-align: right;
        }

        .ant-dropdown-menu-item:hover {
            background: rgba(0, 104, 178, 0.0500) !important;
            color: #0068B2
        }

        .ant-dropdown-menu-title-content {
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                opacity: 1;
                border: 1px solid #313131;
                border-radius: 50%;
                font-size: 12px;
                font-family: Arial-Narrow Bold, Arial;
                font-weight: 700;
                color: #313131;
                cursor: pointer;
            }
        }

    }
}

.dropdownItemC {
    border-radius: 5px;
    box-shadow: none;
    width: 24px;
    cursor: pointer;

    .ant-dropdown-menu {
        box-shadow: none;
        border: 1px solid rgba(0, 104, 178, 0.1000);
        padding: 0;

        .ant-dropdown-menu-item {
            padding: 3px 5px;
            color: #313131;
            text-align: right;
        }

        .ant-dropdown-menu-item:hover {
            background: rgba(0, 104, 178, 0.0500) !important;
            color: #0068B2
        }

        .ant-dropdown-menu-title-content {
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                opacity: 1;
                border-radius: 50%;
                font-size: 12px;
                font-family: Arial-Narrow Bold, Arial;
                font-weight: bold;
                cursor: pointer;
                color: #0068B2;
                border: 1px solid #0068B2
            }
        }

    }
}