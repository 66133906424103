$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.HistoricalEvolutionContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .infoBox {
        padding-left: 10px;
        width: 100%;
        height: 100%;

        .titleTextBox {
            margin-top: 24px;
            height: 30%;
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC-中等, PingFang SC;
            font-weight: normal;
            color: #9E9E9E;

            .titleTextContent {
                height: calc(100% - 37px);
                margin-top: 12px;
                border: 1px solid rgba(0, 104, 178, 0.1);
                border-radius: 10px 10px 10px 10px;
                position: relative;

                // padding: 24px;
                .titleTextItem:first-of-type .titleTextItemTitle {
                    margin-top: 0;
                }

                .titleTextItem {
                    width: 100%;

                    .titleTextItemTitle {
                        font-size: 14px;
                        font-family: PingFang SC-中等, PingFang SC;
                        font-weight: bold;
                        color: #313131;
                        margin-bottom: 16px;
                        margin-top: 16px;
                    }

                    .titleTextItemText {
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9E9E9E;
                    }
                }

                // .titleTextItem:last-of-type {
                //     .titleTextItemTitle {
                //         margin-bottom: 0;
                //     }
                // }

                .copyIcon:hover {
                    color: #0068B2;
                }

                .copySuccessIcon {
                    color: #52c41a;
                }

                .iconBox {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 16px;
                    cursor: pointer;
                    display: none;
                    z-index: 100;
                }
            }

            .titleTextContent:hover {
                .iconBox {
                    display: block;
                }

            }
        }

        .myTableBox {
            height: calc(70% - 50px);
        }
    }
}