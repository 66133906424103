$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.tableModal {

    .ant-modal-content {
        border-radius: 10px;
        // width: 80vw;
        height: 700px;

        .ant-modal-header {
            padding: 10px 15px;
            border-radius: 10px 10px 0 0;

            .ant-modal-title {
                font-size: 16px;
                font-weight: 500;
                color: #313131;
            }
        }

        .ant-modal-close-x {
            width: 43px;
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-body {
            padding: 20px;
            padding-top: 10px;
            height: calc(100% - 44px);
            width: 100%;
            box-sizing: border-box;
        }

        .tableModalBox {
            width: 100%;
            height: 100%;

            .tableModalBoxTitle {
                height: 24px;
                margin-bottom: 6px;
                display: flex;
                align-items: center;
                justify-content: end;

                .ant-btn {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    width: 90px;
                }
            }
        }

        .comOrPeopleLogo {
            height: 30px;
            width: 30px;
            flex: none;
            border-radius: 50%;
            background: #0068B2;
            @include flex-c-c;
            margin-right: 5px;
            flex: none;

            img {
                display: inline-block;
                width: 20px;
                height: 20px;
            }

        }

        .tagBox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 3px;

            .ant-tag {
                font-size: 9.5px;
                border-radius: 10px;
                zoom: 0.8;
                background: #EFEFEF;
                border-color: #EFEFEF;
                color: #313131;
                margin-right: 8px;
                margin-top: 8px;
            }
        }

    }


}