$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondContent {
    @mixin bg-br {
        background: #fff;
        border-radius: 10px;
    }

    height: 100%;
    width: 100%;
    @include flex;
    @include flex-j-s-b;
    // @include bg-br;

    .leftBondContent,
    .rightBondContent {
        height: 100%;
        @include bg-br;
    }

    .leftBondContent {
        width: calc(70% - 20px);
        margin-right: 20px;
        padding: 20px 0;

        .headerSearchBox {
            display: flex;
            align-items: center;

            .ant-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 26px;
                padding: 6px;
                font-size: 14px;
                margin-left: 20px;

                .anticon {
                    display: block;
                    height: 14px;
                    width: 14px;
                }
            }
        }

        .headerTitleContent {
            padding: 0 20px;
        }

        .leftBondContentBox {
            width: 100%;
            height: calc(100% - 40px);
            margin-top: 10px;

            .bondBox {
                height: 100%;
                width: 100%;
                padding-left: 12px;

                .totalBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 20px;

                    .totalDiv {
                        font-size: 12px;
                        font-weight: bold;
                        color: #535353;
                        padding: 0 20px;

                        span {
                            font-size: 20px;
                            color: #0068B2;
                            margin: 0 2px;
                        }
                    }

                    .addAutoCompletePrefixBox {
                        display: flex;
                        align-items: center;

                        .autoCompletePrefixSpan {
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 1px solid #D8D8D8;

                            .anticon {
                                margin-top: 2px;
                            }
                        }

                        .ant-select-auto-complete {
                            .ant-select-clear {
                                right: -2px;
                            }
                        }

                        .ant-select-selection-search-input {
                            text-overflow: ellipsis;
                            // padding: 0 10px 0 0;
                        }

                        .ant-select-selector {
                            padding: 0 10px 0 4px;
                        }

                        .ant-select-selection-search {
                            left: 4px;
                        }
                    }
                }

                .searchBox {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;

                    .optionsBox {
                        display: flex;

                        .optionItem {
                            display: inline-block;
                            cursor: pointer;
                            margin-right: 20px;
                            color: #9E9E9E;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }

                        .borderBottom {
                            width: 200px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                            padding: 0px;
                        }
                    }
                }

                .bondInfiniteScrollBox {
                    height: calc(100% - 68px);
                    margin-top: 10px;
                    overflow-y: auto;
                    @include scrollbar;
                }

                .emptyContent {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .cardBox {
                height: 100%;
                width: 100%;
                padding: 0 20px;

                .emptyContent {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

    .rightBondContent {
        width: 30%;
    }
}