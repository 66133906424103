$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.suspectedRelatedPartyContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .content {
        width: 100%;
        height: calc(100% - 30px);

        .createBtnBox {
            width: 369px;
            height: 60px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #D8D8D8;
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            padding: 0 24px;
            color: #313131;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            margin-left: 15px;
            cursor: pointer;
            user-select: none;
            box-sizing: border-box;

            &:hover {
                border: 1px solid #EAF2FC;
                // color: #0068B2;
                background: #EAF2FC;
            }

            .btnContent {
                display: flex;
                align-items: center;
                justify-content: center;

                .wordIcon {
                    margin-right: 8px;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .btnIcon {
                font-size: 20px;
            }
        }

        .introduceBox {
            height: 216px;
            width: calc(100% - 40px);
            margin-left: 20px;
            box-sizing: border-box;
            // display: flex;
            position: relative;

            .slick-dots,
            .slick-prev,
            .slick-next {
                display: none !important;
            }

            .introduceReportBox:last-of-type {
                margin-right: 0;
            }

            .introduceReportBox {
                cursor: pointer;
                height: 100%;
                // width: calc(25% - 15px);
                width: calc(100% - 20px);
                margin-left: 10px;
                flex: none;
                // margin-right: 20px;
                background: red;
                box-sizing: border-box;
                border-radius: 8px 8px 8px 8px;


                .introduceReportItem {
                    width: 100%;
                    height: 100%;
                    background: -webkit-linear-gradient(top, transparent 10px, rgba(255, 255, 255, 0.2) 11px), -webkit-linear-gradient(left, transparent 10px, rgba(255, 255, 255, 0.2) 11px);
                    background-size: 11px 11px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    .reportType {
                        font-size: 18px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin: 24px 0;
                    }

                    .reportContent {
                        width: calc(100% - 48px);
                        height: 130px;
                        margin: 0 24px;
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 4px 4px 0px 0px;
                        padding: 10px;
                        overflow: hidden;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .myTrackHorizontal {
                width: 60px;
                bottom: 0px;
                background: #D8D8D8;
                border-radius: 10px;
                left: calc(50% - 30px);
                height: 2px !important;

                div {
                    background: #0068B2 !important;
                    border-radius: 10px !important;
                    height: 2px !important;
                }
            }

            .carouselIcon {
                font-size: 46px;
                color: #D8D8D8;
                position: absolute;
                top: calc(50% - 24px);
                cursor: pointer;
                opacity: 0.5;
                overflow: hidden;

            }

            .carouselIcon:hover {
                opacity: 1
            }

            .LeftOutlined {
                left: -20px;

                svg {
                    position: relative;
                    right: 8px
                }
            }

            .RightOutlined {
                right: -20px;

                svg {
                    position: relative;
                    left: 8px
                }
            }

            .ant-carousel .slick-dots {
                position: relative !important;
                bottom: -12px !important;
            }

            .ant-carousel .slick-dots li {
                width: 24px !important;
                height: 2px;
            }

            .ant-carousel .slick-dots li button {
                width: 24px !important;
                height: 2px;
                background: #D8D8D8;
                border-radius: 1px 1px 1px 1px;
                opacity: 1;
            }

            .ant-carousel .slick-dots li.slick-active button {
                background: #0068B2;
            }
        }

        .orderTitle {
            position: relative;
            margin: 24px 0 24px 15px;
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #313131;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            .icon {
                margin-right: 5px;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .recordItemBox {
            width: calc(100% - 15px);
            margin-left: 15px;

            .noData {
                height: 100%;
                width: 100%;
                @include flex-c-c;
            }

            .recordItemContent {
                height: 100%;
                width: 100%;

                .recordItemList {
                    display: flex;
                    flex-wrap: wrap;
                    // justify-content: space-around;
                }

            }
        }
    }

}