$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.subscribeSettingDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding-bottom: 5px;
        padding: 0;
    }

    .ant-checkbox-wrapper {
        font-size: 12px;
        line-height: 16px;
        color: #535353;
    }

    .subscriptionProject {
        @include flex-c-c;
        margin-bottom: 20px;
        .projectKey {
            width: 80px;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
        }
    }

    .subscriptionObject {
        .subscriptionObjectBox {
            padding: 20px;
            font-size: 12px;
        }
    }

    .subscriptionContent {
        .subscriptionContentBox {
            padding: 20px;

            .CheckboxContent {
                @include flex;
                @include flex-a-c;
                flex-wrap: wrap;

                .CheckboxItem {
                    width: calc(25% - 10px);
                    margin-right: 10px;
                }
            }
        }
    }

    .subscriptionMethod {
        .subscriptionMethodBox {
            padding: 20px;
            @include flex;
            @include flex-a-c;
            @include flex-j-s-b;

            .searchItem {
                width: calc(50% - 10px);
                @include flex-c-c;

                .borderBottom {
                    width: calc(100% - 60px);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                    padding: 0px;

                    .ant-input {
                        font-size: 12px;
                    }
                }

                .textInputDiv{
                    color: #535353;
                    height: 20px;
                    line-height: 20px;
                    cursor: pointer;
                    width: calc(100% - 80px);
                    user-select: none;
                    border-bottom: #d9d9d9 1px solid;
                    box-sizing: border-box;
                }
            }

        }
    }

    .subscriptionDate {
        .subscriptionDateBox {
            padding: 20px;

            .dateItem {
                width: 50%;
            }
        }
    }
}