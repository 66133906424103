$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.toolCabinetContent {
    padding: 10px 0;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    width: 100%;
    height: 100%;


    .searchInput {
        width: 100%;
        height: 40px;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .toolInput {
            width: 40%;
            height: 100%;
            background: #F7F7F7;
            border-radius: 26px 26px 26px 26px;
            border: 1px solid #EFEFEF;
            overflow: hidden;

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
                line-height: 38px;
                z-index: 9;
                padding-left: 10px;
            }

            .ant-select-selector,
            .ant-select-selection-search-input {
                width: 100%;
                height: 100%;
                padding-left: 10px;
                font-size: 14px;
                font-family: PingFang SC-常规, PingFang SC;
                font-weight: normal;
                background: #F7F7F7;
                border: none;
            }

            .ant-select-clear {
                // font-size: 20px;
                z-index: 10;
            }
        }
    }

    .typeBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .typeItemBox {
            // width: 100px;
            margin-top: 10px;
            margin-right: 20px;
            @include flex-c-c;
            padding: 0 10px;
            height: 30px;
            // background: red;
            user-select: none;
            cursor: pointer;

            .icon {
                @include flex-c-c;
                width: 30px;
                height: 30px;
                flex: none;
                margin-right: 16px;
                border-radius: 50%;
                // opacity: 0.15;

                .anticon {
                    font-size: 20px;
                    color: red
                }

                .toolIcon {
                    ellipse {
                        fill: #0068B2 !important;
                        fill-opacity: 1 !important;
                    }

                    path {
                        fill: #FFFFFF !important;
                    }
                }
            }

            .title {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: bold;
                color: #313131;
            }
        }
    }

    .itemContentBox {
        .itemContent:first-of-type {
            margin-top: 0px;
        }

        .itemContent {
            margin-top: 20px;

            // height: 100%;
            .itemCardBox {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                // align-items: center;
                // justify-content: space-between;
                padding: 0 5px;
                margin-top: 20px;
            }
        }

        .emptyBox {
            width: 100%;
            height: 100%;
            @include flex-c-c;
        }
    }

    .toolCard {
        width: calc(25% - 15px);
        margin-bottom: 20px;
        padding: 15px;
        height: 75px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1000);
        border-radius: 4px 4px 4px 4px;
        user-select: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        transition: all 250ms cubic-bezier(.02, .01, .47, 1);
        position: relative;

        &:hover {
            box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
            transform: translate(0, -5px);
            transition-delay: 0s !important;

            .infoBox {
                .title {
                    color: #0068B2;
                }
            }

            .focusBox {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .focusBox {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 20px;
            width: 20px;
            font-size: 20px;
            display: none;
            cursor: pointer;
            
            .activeIcon {
                color: #FEC054 !important;
            }
        }

        .cardIcon {
            height: 40px;
            width: 40px;
            // background: #F7F7F7;
            flex: none;
            margin-right: 10px;
            @include flex-c-c;

            img {
                display: block;
                width: 100%;
                // height: 100%;
                max-height: 100%;
            }
        }

        .infoBox {
            width: calc(100% - 50px);

            .title {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: bold;
                color: #313131;
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //不换行
                overflow: hidden; //溢出隐藏
            }

            .info {
                display: block;
                width: 100%;
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #535353;
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //不换行
                overflow: hidden; //溢出隐藏
            }
        }
    }

    .active {
        box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
        transform: translate(0, -5px);
        transition-delay: 0s !important;

        .infoBox {
            .title {
                color: #0068B2;
            }
        }
    }
}