$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.contentMapContent {
    height: 100%;
    width: 100%;
    position: relative;
    .cardItem {
        width: 100%;
        height: 110px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.0500);
        margin-top: 20px;
        cursor: pointer;
        padding: 15px;

        &:first-of-type {
            margin-top: 0;
        }

        &:hover {
            box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.0500);
        }

        .box {
            @include flex;
            width: 100%;
            height: 60px;
        }

        .topBox {
            @include flex;
            height: 60px;
            width: 100%;

            .one {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                @include flex-c-c;
                background-color: #0068B2;
                color: #FFFFFF;
                font-size: 25px;
                margin: 0 15px;
                position: relative;

                svg {
                    height: 22px;
                    width: 22px;
                }

                .ant-checkbox-wrapper {
                    position: absolute;
                    right: -8px;
                    top: -6px;
                }
            }

            .item {

                height: 60px;
                @include flex-c-c;
                position: relative;

                .lineBox {
                    width: calc(100% - 42px);
                    height: 100%;
                    @include flex-c-c;

                    .ant-badge-status-text {
                        margin-left: 3px;
                    }

                    .line {
                        width: calc(100% - 15px);
                        height: 1px;
                        border: 1px solid #D8D8D8;
                    }
                }

                .iconBox {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: #D8D8D8 1px solid;
                    @include flex-c-c;
                    font-size: 18px;
                    color: #D8D8D8;
                }

                svg {
                    height: 16px;
                    width: 16px;
                }


                .textBox {
                    position: absolute;
                    font-size: 12px;
                    font-weight: 500;
                    color: #313131;
                    bottom: -20px;
                    right: 0;
                    user-select: none
                }

            }

            .ant-checkbox-wrapper {
                position: absolute;
                right: -5px;
                top: -3px;

                .ant-checkbox-inner {
                    border-radius: 50%;
                    // background: #0068B2;
                }

                .ant-checkbox-checked .ant-checkbox-inner:after {
                    border: 1px solid #fff;
                    border-top: 0;
                    border-left: 0;
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                    background: #0068B2;
                }

                .ant-checkbox-checked:after {
                    border-radius: 50%;

                }
            }
        }

        .textBox {
            @include flex;
            height: 20px;
            font-size: 12px;
            font-weight: 500;
            color: #313131;

            .oneText {
                width: 90px;
                text-align: center;
                margin-top: 5px;
                user-select: none
            }

        }
    }
    .mapHandleAll{
        position: absolute;
        right: 0;
        top: -40px;
        cursor: pointer;
    }
}