$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.sidebarContainer {
  height: 100%;

  .my-layout {
    height: 100%;
  }

  .logo {
    position: relative;
    float: left;
    width: 100%;
    height: 64px;
    background-size: 50% 32px;

    .logoBox {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding: 0 24px;

      img {
        display: block;
        height: 30px;
        width: auto;
        margin-right: 10px;
      }

      span {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }

  }

  // .logo img {
  //   position: absolute;
  //   left: 25px;
  //   top: 18px;
  //   width: calc(100% - 90px);  
  // }

  .logo .anticon-menu-unfold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 5%;
    font-size: 18px;
    // color: #fff;
  }

  .logo .anticon-menu-fold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // color: #fff;
  }

  .ant-menu {
    background-color: transparent;
  }

  .ant-menu-item {
    line-height: 16px;
  }

  .ant-menu-inline {
    border: none;
  }

  .ant-menu svg {
    fill: #fff;
    font-size: 16px;
    height: 16px;
    width: 16px;
  }

  .ant-menu-title-content {
    color: #fff;
    font-size: 16px;
  }

  .ant-menu-title-content a {
    color: #fff;
    font-size: 16px;
  }

  .ant-menu-submenu-title i {
    color: #fff;
    font-size: 16px;
  }

  .anticon-menu-fold {
    font-size: 18px;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.4);
  }

  .ant-menu-submenu-arrow {
    color: #fff !important;
  }

  .ant-menu-item a:hover {
    color: #fff;
  }

  .ant-menu-item-active {
    background: rgba(255, 255, 255, 0.4) !important;
    color: #fff !important;
  }

  .ant-menu-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}