$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.commentsContent {
    .headerTitle {
        font-size: 14px;
        font-weight: 500;
        color: #313131;
        margin-bottom: 16px;
    }

    .lineItem {
        display: flex;
        margin-top: 16px;

        .title {
            width: 32px;
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            color: #313131;
            margin-right: 5px;
            flex: none;

            .anticon {
                font-size: 12px;
                cursor: pointer;
            }
        }

        .valueBox {
            width: 100%;

            .levelInput {
                font-size: 12px;
                font-family: PingFang SC-中等, PingFang SC;
                font-weight: 400;
                padding: 0;
                margin-top: 16px;
                color: #535353;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);

                .ant-select-selection-search {
                    right: 0;
                    left: 0;
                }

                .ant-select-selector {
                    padding: 0;
                }

            }

            .radioGroupBox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;

                .ant-radio-button-wrapper {
                    width: 52px;
                    height: 22px;
                    padding: 0;
                    @include flex-c-c;
                    font-size: 12px;
                    font-family: PingFang SC-中等, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    border: 1px solid #D8D8D8;
                    border-radius: 4px 4px 4px 4px;
                }

                .ant-radio-button-wrapper:not(:first-child):before {
                    display: none;
                }

                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                    background: #0068B2;
                    border: 1px solid #0068B2;
                    color: #ffffff
                }
            }
        }

    }

    .btnBox {
        @include flex-c-c;
        flex-direction: row;
        margin: 20px 0 10px 0;
        width: 100%;

        .ant-btn {
            margin-left: 0;

            &:first-of-type {
                margin-right: 15px;
            }
        }

    }

    .ant-select-selection-overflow-item {
        @include flex-c-c;

        // margin-top: 8px;
        .ant-tag {
            height: 30px;
            @include flex-c-c;
            border: none;
            background: #ffffff;
            margin: 0;
            padding-left: 0
        }
    }
}

.levelInputPopup {
    .ant-select-item {
        padding: 2px 5px;
        min-height: unset !important;
    }

    .ant-select-item-option-content {
        font-size: 12px;
    }
}