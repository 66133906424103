$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondTableContentBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
    // background: #fff;
    // padding: $mainPadding;

    .searchBox {
        min-height: 180px;
        width: 100%;
        background: #fff;
        margin-bottom: 10px;
        border-radius: 10px;
        padding: $mainPadding;

        .searchSpinBox {
            height: 154px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .searchItems {
            height: calc(100% - 36px);
            margin-top: 10px;
            width: 100%;
            padding-left: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .searchItem {
                width: 100%;
                display: flex;
                // align-items: center;
                margin-top: 14px;

                &:last-of-type {
                    align-items: center;

                    .ant-select-selector {
                        padding: 0;
                    }

                    .ant-select-selection-search {
                        right: 0;
                        left: 0;
                    }

                }

                &:first-of-type {
                    margin-top: 0;

                    .optionsItems {
                        width: calc(100% - 105px);
                        margin-left: 4px;
                    }
                }

                // &:nth-of-type(2) {
                //     width: calc(100% - 90px);
                //     background-color: #a4a2a21a;
                //     border-radius: 4px;
                //     margin-left: 85px;
                //     padding: 4px 0;
                //     .optionsItems{
                //         margin-left: 5px;
                //     }
                // }

                .helpIcon {
                    display: flex;
                    height: 14px;
                    width: 14px;
                    // align-items: center;
                    justify-content: center;
                    margin-top: 4px;
                    cursor: help;
                    color: #61D4AD;

                    // &:hover {
                    //     color: #0068B2;
                    // }
                }

                .label {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #9E9E9E;
                }

                .optionsItems {
                    width: calc(100% - 90px);
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .optionsItem {
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #313131;
                        margin-right: 20px;
                        cursor: pointer;
                        flex: none;
                        user-select: none;

                        &:last-of-type {
                            margin-right: 0px;
                        }

                        // &:hover {
                        //     color: #0068B2;
                        // }
                    }

                    .disOptionsItem {
                        color: #939393;
                        cursor: no-drop !important;
                    }

                    .activeOptionsItem {
                        color: #0068B2;
                    }
                }

                .rangePickerBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .anticon {
                        margin: 0 2px;
                        color: #9E9E9E;
                    }
                }
            }
        }
    }

    .optionsDiv {
        height: 22px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-right: 10px;

        .optionsBox {
            display: flex;

            .optionItem {
                display: inline-block;
                cursor: pointer;
                margin-right: 20px;
                color: #9E9E9E;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .borderBottom {
                width: 200px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                padding: 0px;
            }
        }

        .downloadIcon {
            cursor: pointer;
            font-size: 16px;

            &:hover {
                color: #0068B2;
            }
        }
    }

    .infoBox {
        padding-left: 10px;
        width: 100%;
        height: calc(100% - 222px);
        background: #fff;
        border-radius: 10px;
        padding: 20px 0;

        .cardContentBox {
            height: 100%;
            width: 100%;

            .cardsBox {
                padding: 0 20px;

                .cardItem {
                    height: 160px;
                    width: 100%;
                    border-radius: 10px 10px 10px 10px;
                    border: 1px solid #EFEFEF;
                    padding: 20px;
                    margin-bottom: 20px;
                    position: relative;
                    display: flex;

                    &:hover {
                        box-shadow: 8px 8px rgba($color: #EFEFEF, $alpha: 0.4);
                        cursor: pointer;
                    }

                    &:last-of-type {
                        margin-bottom: 0px;
                    }

                    .imgBox {
                        width: 120px;
                        height: 120px;
                        margin-right: 20px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        flex-wrap: wrap;
                        border-radius: 4px 4px 4px 4px;

                        .imgBg {
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                        }

                        .iconImg {
                            display: block;
                            width: 40px;
                            height: 40px;
                        }

                        .typeBox {
                            width: calc(100% - 20px);
                            font-size: 14px;
                            font-family: PingFang SC-Bold, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            margin-top: 8px;
                            text-align: center;
                            line-height: 16px;
                        }
                    }

                    .publicSentimentCardContent {
                        width: calc(100% - 140px);

                        .tipBox {
                            font-size: 12px;
                            color: #9E9E9E;
                            flex: none;
                            margin-left: 8px;

                            span {
                                margin-right: 10px;

                                &:last-of-type {
                                    margin-right: 0;
                                }
                            }
                        }

                        .cardTitleBox {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                        }

                        .ant-typography {
                            // width: 100%;
                            font-size: 16px;
                            font-family: PingFang SC-粗体, PingFang SC;
                            font-weight: 600;
                            color: #313131;
                            cursor: pointer;
                            
                            &:hover {
                                color: #0068B2;
                            }
                        }

                        .contentBoxTitle {
                            display: flex;
                            align-items: center;
                            margin-top: 10px;
                            width: 100%;
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 400;
                            color: #535353;

                            .tagsBox {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                margin-left: 10px;

                            }

                            .ant-tag {
                                font-size: 9.5px;
                                border-radius: 10px;
                                zoom: 0.8;
                                background: #EFEFEF;
                                border-color: #EFEFEF;
                                color: #313131;
                            }
                        }

                        .contentBox_ {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .domItemBox {
                                display: flex;
                                margin-top: 10px;

                                .labelSpan {
                                    flex: none;
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #9E9E9E;
                                    margin-right: 10px;
                                }

                                .ant-typography {
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #535353;
                                }
                            }
                        }


                    }

                    .importSvg {
                        position: absolute;
                        top: 60px;
                        right: 20px;
                    }
                }
            }

            .paginationBox {
                padding: 0 20px;
                margin-top: 13px;
                display: flex;
                flex-direction: row-reverse;
            }
        }

        .emptyBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }
}

.eventTypePopover {
    min-width: 250px;
    width: auto
}

.eventTypePopoverBox {

    .eventTypePopoverTitle {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
    }

    .eventTypePopoverItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;

        span {
            color: #535353;
        }

        .scope {
            color: #0068B2;
            margin-left: 10px;
        }
    }
}

.eventTypeParagraphTooltip {
    max-width: 450px;

    .eventTypeParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}