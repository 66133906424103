$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.creatCollenctionModal {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 15px;
            border-radius: 10px 10px 0 0;

            .ant-modal-title {
                font-size: 16px;
                font-weight: 400;
                color: #313131;
            }
        }

        .ant-modal-close-x {
            width: 43px;
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-body {
            padding: 20px 0;

        }
    }

    .messageModalContent {
        @include flex-c-c;
        flex-direction: column;
        max-height: 70vh;
        overflow-y: auto;
        @include scrollbar;
        padding: 0 20px;
        text-align: left;

        h3 {
            width: 100%;
            font-size: 14px;
            font-family: PingFang-SC-Regular-Regular, PingFang-SC-Regular;
            font-weight: 400;
            color: #313131;
            margin-bottom: 16px;
            text-align: left;
            margin-top: 16px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .textSpan {
            width: 100%;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #535353;
        }

        .tipSpan {
            width: 100%;
            font-size: 14px;
            font-family: PingFang-SC-Regular-Regular, PingFang-SC-Regular;
            font-weight: 400;
            color: #FE545F;
        }

        .btnBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;
            margin-top: 40px;
        }

        .flowBox {
            width: 100%;
            height: 120px;
            // background: #535353;
            margin-bottom: 30px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .flowLineBox {
                width: calc(100% - 150px);
                height: 14px;
                // background: #16D8D8;
                position: absolute;
                top: 26px;
                left: 80px;
                z-index: 1;

                li {
                    padding: 7px 20px;
                    line-height: 40px;
                    background: #16D8D8;
                    display: inline-block;
                    color: #fff;
                    position: relative;
                    float: left;
                    margin: 1px;

                    &:nth-of-type(1) {
                        width: 110px;
                    }

                    &:nth-of-type(2) {
                        width: 110px;
                    }

                    &:nth-of-type(3) {
                        width: 100px;
                    }

                    &:nth-of-type(4) {
                        width: 100px;
                    }

                    &:nth-of-type(5) {
                        width: 70px;
                    }

                    &:nth-of-type(6) {
                        width: 70px;
                    }

                    &:nth-of-type(7) {
                        width: 76px;
                    }

                    &:nth-of-type(8) {
                        width: 50px;
                    }
                }

                li:after {
                    content: '';
                    display: block;
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-left: 7px solid #16D8D8;
                    position: absolute;
                    right: -7px;
                    top: 0;
                    z-index: 1;
                }

                li:before {
                    content: '';
                    display: block;
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-left: 7px solid #FFFFFF;
                    position: absolute;
                    left: 0px;
                    top: 0;
                }

                .flowLine {
                    width: 100%;
                    height: 1px;
                    border-top: 1px dashed #ffffff;
                    position: absolute;
                    top: 7.5px;
                    z-index: 2;
                }
            }

            .downloadBox {
                position: absolute;
                top: -38px;
                right: 0;
                user-select: none;
            }

            .flowItem {
                width: 20%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                z-index: 2;

                .icon {
                    width: 56px;
                    height: 56px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 6px 0px rgba(0, 104.00000140070915, 178.00000458955765, 0.25);
                    opacity: 1;
                    border: 1px solid #16D8D8;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        width: 24px;
                        height: 24px;
                    }
                }

                .contentBox {
                    height: calc(100% - 76px);
                    width: 100%;
                }

                .text {
                    width: 100%;
                    font-size: 14px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: 500;
                    color: #0068B2;
                    text-align: center;
                }

                .tip {
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFang-SC-Regular-Regular, PingFang-SC-Regular;
                    font-weight: 400;
                    color: #313131;
                }
            }

            .flow1 {
                width: 20%;
            }

            .flow2 {
                width: 30%;
            }

            .flow3 {
                width: 18%;
            }

            .flow4 {
                width: 18%;
            }

            .flow5 {
                width: 14%;
            }
        }
    }
}

.creatCollenctionModalTooltip {
    max-width: 280px;

    .creatCollenctionModalTip {
        max-height: 225px;
        width: 268px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}