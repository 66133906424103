$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.tableBox {
    height: 100%;
    width: 100%;
    padding-top: 10px;

    .dateBox {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        // margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dataInput {
            @include flex-c-c;

            .inputBox {
                @include flex-c-c;
                margin-left: 20px;
                width: 460px;

                .label {
                    font-size: 14px;
                    color: #313131;
                    width: 60px;
                    flex: none;
                }

                .ant-select {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                    box-sizing: border-box;
                }
            }

            .sumAmountBox {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
                margin: 0 10px 0 20px;
                display: flex;
                align-items: baseline;
                justify-content: center;

                p {
                    display: block;
                    height: 32px;
                    font-size: 28px;
                    font-family: Century Gothic-Bold, Century Gothic;
                    font-weight: bold;
                    color: #FE545F;
                    margin: 0 5px;
                    line-height: 25px;
                }

                .unit {
                    font-size: 14px;
                    font-family: PingFang SC-中等, PingFang SC;
                    font-weight: normal;
                    color: #FE545F;
                }

                .anticon {
                    margin-right: 2px;
                    margin-left: 2px;
                    cursor: help;
                    vertical-align: 0
                }
            }
        }

        .iconBox {
            color: #313131;
            font-size: 16px;

            .anticon:hover {
                // text-decoration: underline
                color: #0068B2;
            }
        }
    }

    .searchTypeBox {
        height: 50px;
        display: flex;
        margin-bottom: 10px;

        .searchTypeContent {
            height: 100%;
            border: 1px solid #EFEFEF;
            border-radius: 50px;
            padding: 5px 12px;
            box-sizing: border-box;
            display: flex;
        }

        .typeBtnItem {
            height: 38px;
            min-width: 100px;
            padding: 0 10px;
            cursor: pointer;
            border-radius: 38px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #313131;
            @include flex-c-c;

            .numSpan {
                font-size: 18px;
                font-family: Century Gothic-Bold, Century Gothic;
                font-weight: 700;
                margin-left: 5px;
            }
        }

        .activeTypeBtnItem {
            background: #0068B2;
            color: #fff;

            .iconSvg {
                color: #fff !important;
            }

            .editIcon {
                color: #fff !important;
            }
        }

    }

    .tableContent {
        height: calc(100% - 45px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .notCur {
            td {
                cursor: default;
            }
        }

        .selectItem {
            td {
                background: #f2f6fa !important;
            }
        }

        .ant-table-cell {
            position: relative;
        }

        .czIconBox {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            color: #313131;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-weight: 500;
            cursor: default;

            .sourceIcon {
                height: 16px;
                width: 16px;
                @include flex-c-c;
                margin-right: 5px;
                cursor: pointer;

                &:hover {
                    color: #0068B2;
                }

                div {
                    height: 16px;
                    width: 16px;
                    @include flex-c-c;
                }

                svg {
                    height: 16px;
                    width: 16px;
                }
            }

            .iconSvg {
                height: 16px;
                width: 16px;

                svg,
                div {
                    height: 16px;
                    width: 16px;
                }
            }

            .anticon {
                margin-right: 5px;
                cursor: pointer;

            }

            .anticon:hover {
                // text-decoration: underline
                color: #0068B2;
            }
        }

        .paginationBox {
            margin-top: 13px;
            display: flex;
            flex-direction: row-reverse;
        }

        .ripTagBox {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 14px;
            height: 14px;
            background: #FEC054;
            color: #fff;
            border-radius: 2px 2px 2px 2px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                width: 14px;
                height: 14px;
            }
        }
    }

    .tagBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 3px;

        .ant-tag {
            font-size: 9.5px;
            border-radius: 10px;
            zoom: 0.8;
            background: #EFEFEF;
            border-color: #EFEFEF;
            color: #313131;
            margin-right: 8px;
            margin-top: 8px;
        }
    }

    .controller {
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #6CAEFF;
        background: #6CAEFF;
        font-weight: 400;
        color: #fff;
        line-height: 16px;
        transform: scale(0.85);
        margin: 0;
    }

    .comOrPeopleContent {
        width: 100%;
        height: 100%;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .comOrPeopleLogo {
        height: 30px;
        width: 30px;
        flex: none;
        border-radius: 50%;
        background: #0068B2;
        @include flex-c-c;
        margin-right: 5px;
        flex: none;

        img {
            display: inline-block;
            width: 20px;
            height: 20px;
        }

    }

    .ant-progress-circle .ant-progress-text {
        transform: scale(0.5) translate(-100%, -100%);
        width: 160%;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #535353 !important;
    }
}

.moreDropdown {
    border-radius: 5px;
    box-shadow: none;
    z-index: 99;
    width: 40px;

    .ant-dropdown-menu {
        box-shadow: none;
        border: 1px solid rgba(0, 104, 178, 0.1000);
        padding: 0;

        .ant-dropdown-menu-item {
            padding: 3px 5px;
            padding-bottom: 0px;
            color: #313131;
        }

        .ant-dropdown-menu-item:hover {
            background: rgba(0, 104, 178, 0.0500) !important;
            color: #0068B2
        }

        .ant-dropdown-menu-item:last-of-type {
            padding-bottom: 3px;
        }
    }
}

.subjectScopeSearchPoP {
    .ant-select-item {
        width: 100%;
    }

    // .ant-select-item-option-selected{
    //     .ant-select-item-option-content{
    //         width: calc(100% - 40px);
    //     }
    // }
    .ant-select-item-option-content {
        width: 100%;
        display: flex;
        align-items: center;

        .textItem {
            color: rgba(0, 0, 0, .85);
            max-width: calc(100% - 50px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ant-tag {
            margin: 0 0 0 5px;
            padding: 0 1px;
            font-size: 12px;
            transform: scale(0.8);
            font-weight: normal !important;
            border-radius: 4px 4px 4px 4px;
            height: 20px;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }
    }
}

.sharesTip {
    z-index: 99;
    padding-top: 0px;
    padding-bottom: 0px;

    // .ant-tooltip-arrow{
    //     width: 14px;
    //     height: 14px;
    // }
    .ant-tooltip-inner {
        min-height: 20px;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 4px;
        user-select: none;
    }
}

.filterDropdownBox {
    width: 265px;
    padding: 8px;

    .searchInput {
        margin-bottom: 5px;
        width: 100%;
    }

    .checkBox {
        max-height: 300px;
        overflow-y: auto;
        @include scrollbar;
        width: 100%;

        .ant-checkbox-wrapper {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            white-space: nowrap;
        }
    }

    .loadingBox{
        width: 100%;
        @include flex-c-c;
        margin: 0;
    }
    
    .filterDropdownBtnBox {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .ant-btn {
            width: 60px;
            margin-left: 0
        }

        .noBg {
            padding: 0px;
        }
    }
}


.tableColumnsParagraphTooltip {
    max-width: 450px;

    .tableColumnsParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}

.checkboxItemBox{
    white-space: nowrap;
}