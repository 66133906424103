$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.uploadFileDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        // padding-top: 20px;
        padding: 20px
    }

    .uploadFileDrawerContent {
        width: 100%;
        height: 100%;

        .downloadTemplate {
            height: 30px;
            margin-bottom: 20px;
            @include flex;
            @include flex-j-s-b;

            .iconBox {
                font-size: 14px;
                font-weight: 500;
                color: #0068B2;
                cursor: pointer;
                user-select: none;
                @include flex-c-c;
                // text-decoration:underline;
                .text {
                    border-bottom: 1px solid #0068B2;
                }

                .anticon {
                    font-size: 16px;
                    margin-right: 5px
                }
            }


        }

        .draggerBox{
            height: 160px;
        }
        .uploadBox {
            width: 100%;
            height: 100%;
            @include flex-c-c;
            flex-direction: column;

            .anticon {
                height: 66px;
                width: 66px;
                color: #D8D8D8;
    
                svg {
                    height: 66px;
                    width: 66px;
                }
            }

            .text {
                font-size: 14px;
                font-weight: normal;
                color: #9E9E9E;
                padding: 0 3px;
                margin-top: 10px;
                span {
                    color: #0068B2;
                }
            }
        }

        .fileTip {
            font-size: 12px;
            font-family: PingFang SC-常规, PingFang SC;
            font-weight: normal;
            color: #9E9E9E;
            margin: 8px 0 ;
        }

        .fileLineItem{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            cursor: pointer;
        }
        .fileItem{
            @include flex-c-c;
            .ant-typography{
                margin-left: 5px;
                margin-bottom: 0;
            }
        }
        .closeIcon:hover{
            color: #0068B2;
        }
    }
}