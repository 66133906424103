$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.biddingAnalysisContent {
    width: 100%;
    height: 100%;
    background: #fff;

    .dataInput {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        // @include flex-c-c;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .inputBox {
            @include flex-c-c;
            margin-left: 20px;
            width: 460px;

            .label {
                font-size: 14px;
                color: #313131;
                width: 60px;
                flex: none;
            }

            .ant-select {
                border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                box-sizing: border-box;
            }
        }
    }

    .lineDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #0068B2;

        i {
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #0068B2;
        }

        span {
            display: inline-block;
            flex: none;
            padding: 0 16px;
        }
    }

    .chartsBox {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .chartsLeftBox {
            width: 450px;
            height: 100%;

            .chartBox:first-of-type {
                margin-top: 0px;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
        }

        .chartsRightBox {
            width: calc(100% - 470px);
            height: 100%;
            margin-left: 20px;
        }

        .chartBox {
            width: 100%;
            height: 330px;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid rgba(0, 104, 178, 0.1);
            flex: none;
            margin-top: 20px;
            box-sizing: border-box;

            &:nth-of-type(1) {
                margin-top: 0;
            }

            .headerTitleBox {
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #313131;
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .anticon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }

            .tableContentBox {
                height: calc(100% - 54px);
                width: 100%;
                // padding: 0 16px 16px 16px;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .paginationBox {
                    margin-top: 13px;
                    display: flex;
                    flex-direction: row-reverse;
                }

                .searchBox {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    margin-bottom: 10px;

                    .searchItemBox {
                        width: 80px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;
                        cursor: pointer;

                        &:hover {
                            color: #0068B2;
                        }

                        &:focus {
                            color: #0068B2;
                        }
                    }
                }
            }

        }

    }

}

.searchOptionsPopover {
    padding-top: 0px;
    padding-left: 5px;
    max-width: 300px;
    max-height: 400px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        padding: 6px 0px;

    }

    .select-item-option-content {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;

        &:hover {
            background-color: #efefef;
        }

        .selectOptionBox {
            width: 100%;
            height: 100%;
            display: flex;
        }

        .textItem {
            color: rgba(0, 0, 0, .85);
            // max-width: calc(100% - 40px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ant-tag {
            margin: 0 0 0 5px;
            padding: 0 1px;
            font-size: 12px;
            transform: scale(0.8);
            font-weight: normal !important;
            border-radius: 4px 4px 4px 4px;
            height: 20px;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }

        .selectIcon {
            display: none;
        }
    }

    .select-active-item-option-content {
        background-color: #daecf2;

        .textItem {
            font-weight: 600;
        }

        .selectOptionBox {
            width: calc(100% - 20px);
        }

        .selectIcon {
            display: block;
            color: #0068B2;
            flex: none;
            margin-left: 6px;
        }
    }
}