$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.quarterlyDistributionContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        // position: relative;
        // z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .yearSelectBox {
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 20;
        }
    }

    .emptyContent {
        width: 100%;
        height: 270px;
        @include flex-c-c;
    }

    .quarterlyDistributionContentBox {
        height: 270px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #quarterlyDistributionContentBox {
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 8;

            // ~div,
            // canvas {
            //     width: 100% !important;
            //     height: 100% !important;
            // }
        }


    }
}