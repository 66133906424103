$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.addComp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    line-height: 16px;

    .name {
        color: rgba(0, 0, 0, .85);
        display: flex;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .nameTag {
            display: flex;
            flex: none;
            height: 16px;
            width: 50px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            text-align: center;
            padding: 0px;
            border-radius: 20px;
            margin-right: 6px;
        }
    }

    .info {
        display: inline-block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #8492a6;
        font-size: 10px
    }
}

.nameSearchInput {
    .ant-select-arrow-loading {
        right: 30px;
        font-size: 14px;
    }

    .ant-select-selector {
        padding: 0 !important;
        .ant-select-selection-search{
            width: 100%;
            left: 0;
            right: 0;
            .ant-select-selection-search-input{
                padding: 0 11px;
            }
        }
    }

    .ant-select-selection-placeholder {
        padding: 0 11px !important;
    }
}