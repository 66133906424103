$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
 .casePartieseModal {
   

     .ant-modal-content {
         border-radius: 10px;

         .ant-modal-header {
            @include flex;
           
             height: 56px;
             padding: 16px 24px 0 24px;
             border-radius: 10px 10px 0 0;

         
             .ant-modal-title {
                 font-size: 16px;
                 font-weight: 400;
                 color: #313131;
             }
         }

         .ant-modal-close-x {
             width: 43px;
             height: 43px;
             margin-right: 5px;
         }

         .ant-modal-body {
             padding: 0;
              .ant-form-item {
               margin-bottom: 15px;
               }
         }
     }

     .casePartieseModalContent {
         @include flex;
         @include flex-j-s-b;
         flex-wrap: wrap;
         padding-bottom: 0;
         padding: 20px 24px;
         width: 100%;

         .title {
             font-size: 12px;
             color: #FE545F;
             margin-bottom: 20px;
         }

         .ant-checkbox-wrapper {
             font-size: 12px;
         }

         .buttonValue {
             margin-left: 140px;

             .ant-btn {
                 width: 96px;
                 height: 32px;
             }
         }
         .ant-checkbox-checked + span {
            color: #313131;
         }
         
     }
      
 }