$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.RulesDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        // padding-top: 20px;
        padding: 20px
    }

    .itemContentBox {
        display: flex;
        margin: 20px 0;
        flex-wrap: wrap
    }

    .checkBox {
        @include flex-c-c;
        height: 32px;
        background: #EFEFEF;
        border-radius: 4px 4px 4px 4px;
        padding: 0 10px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #535353;
        margin: 0px 10px 20px 14px;
        cursor: pointer;
        position: relative;

        .iconCircle{
            display: block;
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -6px;
            right: -6px;
            background: #bbbbbb;
            font-size: 7px;
            border-radius: 50%;
            
            .anticon {
                color: #fff;
            }
        }
    }

    .checkedBox {
        color: #FFFFFF;
        background: #0068B2;

        .iconCircle {
            background: #61D4AD !important;
        }
    }

    .checkboxItem {
        width: 50%;
        padding-left: 14px;
    }

    .numberInputBox {
        // margin-top: 10px;
        width: 100%;
        padding-left: 14px;
        display: flex;
        align-items: center;
        position: relative;

        .numberInput {
            width: calc(100% - 135px);
            margin: 0 8px;
        }

        .inputErrorMsgBox {
            position: absolute;
            top: 34px;
            left: 120px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FE545F;
        }
    }

    .ant-popover {
        height: 22px;
        z-index: 11;
    }

    .ant-popover-arrow {
        width: 11px;
        height: 14px;
        left: 4px;
    }

    .ant-popover-inner-content {
        padding: 0;
        background: #FE545F;
        color: #fff;
        border-radius: 4px;
    }

    .ant-popover-arrow-content {
        background: #FE545F;
        --antd-arrow-background-color: #FE545F;
    }

    .editText {
        font-size: 10px;
        font-family: Arial-Regular, Arial;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0 2px;
        border-radius: 4px;
    }

}