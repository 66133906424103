$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondHomeContent {
  height: 100%;
  width: 100%;

  .my-layout {
    // height: 100%;
    height: calc(100% - 48px);
    width: 100%;
    // flex-direction: row
  }

  .closeBox {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFE6E7;
    color: #FE545F;

    .anticon {
      font-size: 16px;
      margin-right: 10px;

    }
  }

  .stateRunBox {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(254, 192, 84, 0.15);
    color: #FFB012;

    .anticon {
      font-size: 16px;
      margin-right: 10px;

    }
  }

  .contentBox {
    display: flex;
    flex-direction: row;
    height: calc(100% - 48px);
    background: #F7F7F7;
  }

  .my-content {
    padding: 20px;
    padding-top: 0px;
    background-color: #f7f7f7;
    margin-top: 20px;
    position: relative;

    .contentBox {
      height: 100%;
      width: 100%;
      // background: #fff;
      border-radius: 10px;
    }

    .scrollbars {
      border-radius: 10px;
      // background: #fff;
    }

  }

  .ant-layout-content {
    overflow: auto;
  }

  .left-title-concent {
    font-size: 16px;
    color: #313131;
    // margin: 0px 68px 0px 24px;
    font-weight: 500;
    @include flex-c-c;

    .homeIcon {
      width: auto;
      height: 20px;
      @include flex-c-c;
      margin-right: 5px;

    }

    .homeBox {
      @include flex-c-c;
      cursor: pointer;
      user-select: none;
      height: 16px;
    }

    .homeTip {
      margin-right: 5px;
      color: #9E9E9E;
      height: 16px;
      line-height: 16px;
    }

    .xie {
      margin-right: 5px;
      user-select: none;
    }

    .projectName {
      // height: 16px;
      // line-height: 16px;
      max-width: 800px;
      overflow: hidden; //溢出隐藏
      white-space: nowrap; //禁止换行
      text-overflow: ellipsis; //...
    }
  }

}

.headerTipContent {
  max-width: none;

  .headerTip {
    .headerTipTitle {
      font-weight: 500;
      font-size: 14px;
    }

    .headerTipText {
      font-size: 12px;
      font-weight: 0;
    }
  }
}