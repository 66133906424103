$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.TimeLineContent {
    flex: none;
    width: 100%;
    height: 280px;
    background: white;
    position: relative;
    border-radius: 10px;
    border: 1px solid #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .title {
        position: absolute;
        top: 16px;
        left: 16px;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
    }

    .dateItemBox {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }


    .dateItem {
        height: 100%;
        width: 12.5%;
        flex: none;
    }



    .leftIcon {
        position: absolute;
        top: 82px;
        left: 16px;
        color: #0068B2;
        cursor: pointer;
        font-size: 24px;
    }

    .rightIcon {
        position: absolute;
        top: 82px;
        right: 16px;
        color: #0068B2;
        cursor: pointer;
        font-size: 24px;
    }

    .dateBox {
        height: 20px;
        width: 100%;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #535353;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lineBox {
        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .fileBox {
        height: calc(100% - 68px);
        width: 100%;
        // overflow-y: auto;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        // @include scrollbar
    }

    .fileBox a {
        display: block;
        width: 100%;
        margin-bottom: 3px;
        text-align: center;
        color: #535353;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
    }

    .fileBox .moreDiv {
        text-align: center;
        cursor: pointer;
        color: #0068B2;
        user-select: none;
    }

    .fileBox .moreDiv:hover {
        color: #0068B2;
    }

    .fileBox span {
        display: block;
        width: 100%;
        margin-bottom: 3px;
        text-align: center;
        color: #535353;
    }

    .fileBox a:hover {
        color: #0068B2;
    }

    .leftLine,
    .rightLine {
        width: 100%;
        height: 2px;
        background: #0068B2;
        z-index: 9;
    }

    .circle {
        height: 16px;
        width: 16px;
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 8px);
        border: 2px solid #0068B2;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
        background: white;
    }

    .circle span {
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #0068B2;
    }

    .firstDateItem {
        width: 9.375%;

        .leftLine {
            width: 33.33%;
            flex: none;
        }

        .circle {
            left: calc(33.33% - 8px)
        }

        .dateBox {
            padding-right: 33%;
        }

        .fileBox {
            padding-right: 33%;
        }
    }


}

.timeLineContentPopover {
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 230px;

    // .ant-popover-arrow {
    //     display: none;
    // }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        max-width: 197px;
        max-height: 315px;
        overflow-y: auto;
        @include tooltipScrollbar;
        padding: 8px 5px 8px 8px;
    }
    .fileBox {
        height: calc(100% - 68px);
        width: 100%;
        // overflow-y: auto;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        // @include scrollbar
    }

    .fileBox a {
        display: block;
        width: 100%;
        margin-bottom: 3px;
        text-align: center;
        color: #535353;
        text-decoration: none;
    }

    .fileBox span {
        display: block;
        width: 100%;
        margin-bottom: 3px;
        text-align: center;
        color: #535353;
    }

    .fileBox a:hover {
        color: #0068B2;
    }
}
.timeLineContentModal {
    .ant-modal {
        width: 70vw !important;

    }

    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-modal-header {
        border-radius: 10px 10px 0 0;
    }

    .ant-modal-body {
        padding: 20px 0;
    }

    .showContentBox {
        // font-size: 12px;
        font-family: PingFang SC-常规, PingFang SC;
        font-weight: normal;
        color: #535353;
        // white-space: pre-line;
        max-height: 70vh;
        overflow-y: auto;
        padding: 0 20px;

        p {
            // margin-bottom: 0;
        }
    }
}