$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.myProjectsContent {
    height: 100%;
    width: 100%;

    .iconBox {
        font-size: 14px;
        font-weight: 500;
        color: #0068B2;
        cursor: pointer;
        user-select: none;

        .anticon {
            margin-right: 5px
        }

        &:hover {
            text-decoration: underline
        }
    }

    .noData {
        width: 100%;
        height: 100%;
        @include flex-c-c;
    }

    .cardBox {
        @include flex;
        // @include flex-j-s-b;
        flex-wrap: wrap;
        margin-top: -20px;
        padding-top: 2px;

        .cardItem {
            width: calc(33.3% - 15px);
            margin-top: 20px;
            border-radius: 8px;
            user-select: none;
            cursor: pointer;
            transition: transform .2s, box-shadow .2s;
            background: #FBFBFD;
            border: none;

            &:hover {
                transition: transform .2s, box-shadow .2s;
                transform: translateY(-2px);
                box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);
            }

            .ant-card-body {
                width: 100%;
                height: 100%;
                padding: 15px;

                .cardContent {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    flex-direction: column;
                }

                .cardTitle {
                    width: 100%;
                    @include flex;
                    margin-bottom: 15px;
                    justify-content: space-between;

                    .title {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 500;
                        color: #313131;
                        user-select: none;
                    }

                    span {
                        // @include flex;
                        display: block;
                        cursor: pointer;
                        // flex-direction: row-reverse;
                        width: 16px;
                        font-size: 16px;
                        padding-top: 5px
                    }
                }

                .cardLabel {
                    // @include flex-c-c;
                    display: flex;
                    justify-content: center;
                    font-size: 12px;
                    margin-bottom: 10px;

                    .label {
                        width: 50px;
                        font-weight: normal;
                        color: #9E9E9E;
                        margin-right: 10px;
                        user-select: none
                    }

                    .value {
                        width: calc(100% - 60px);
                        color: #535353;
                    }
                }

                .cardLabel:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .headerTitleContent {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .ant-tabs-nav {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .cardBox {
        padding: 0 20px;
    }

    .headerTabPaneBox {
        position: absolute;
        left: 130px;
        top: 1px;
        display: flex;

        .headerTabPane {
            height: 24px;
            line-height: 24px;
            // width: 80px;
            margin-right: 24px;
            cursor: pointer;
            border-radius: 24px;
            padding: 0px 10px;
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #9E9E9E;
        }

        .headerTabPane:hover {
            background: #6CAEFF;
            color: #ffffff;
        }

        .activeHeaderTabPane {
            background: #6CAEFF;
            color: #ffffff;
        }
    }
}