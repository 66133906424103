$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.collenctionInfoDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px
    }

    .creatCollenctionDrawerContent {
        padding: 0px 20px 20px 20px;
        width: 100%;
        height: 100%;

        .collenctionInfoCard {
            margin-bottom: 10px;

            .topBox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                h3 {
                    font-size: 14px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: 500;
                    color: #0068B2;
                    margin-bottom: 0;
                }

                .textBox {
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9E9E9E;
                    display: flex;
                    align-items: center;

                }
            }

            .bottomBox {
                height: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .scheduleBox {
                    // width: 192px;
                    height: 22px;
                    display: flex;

                    .tag {
                        width: 56px;
                        height: 22px;
                        flex: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        font-size: 10px;
                        font-family: PingFang-SC-Regular-Regular, PingFang-SC-Regular;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-right: 16px;
                    }

                    .scheduleContent {
                        width: 120px;
                        height: 22px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            display: block;
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            background: #0068B2;
                            z-index: 2;
                        }

                        .line {
                            height: 2px;
                            width: calc(50% - 30px);
                            position: absolute;
                            z-index: 1;
                            top: calc(50% - 1px);

                            &:nth-of-type(1) {
                                left: 20px;
                            }

                            &:nth-of-type(2) {
                                left: calc(50% + 10px);
                            }
                        }
                    }

                    .overtimeBox {
                        margin-left: 20px;
                        color: #FE545F;
                        font-size: 12px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                    }
                }

                .iconBox {
                    font-size: 14px;
                    color: #313131;
                    display: flex;
                    align-items: center;

                    .anticon {
                        cursor: pointer;
                    }

                    .anticon:hover {
                        color: #0068B2;
                    }

                    .icon {
                        margin-right: 6px;
                        cursor: pointer;

                        path {
                            fill: #313131 !important;
                        }

                        ellipse {
                            stroke: #313131 !important;
                        }

                    }

                    .icon:hover {

                        path {
                            fill: #0068B2 !important;
                        }

                        ellipse {
                            stroke: #0068B2 !important;
                        }
                    }
                }
            }
        }
    }

    .btnBox {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 20px;
    }
}

.scheduleContentTip {
    max-width: 300px;

    .scheduleTipContent {
        width: 280px;

        .scheduleTipItem {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            div:first-of-type {
                color: #9E9E9E;
                font-weight: bold;
            }

            div:nth-of-type(2) {
                color: #FFFFFF;
            }

            span {
                color: #FE545F;
            }
        }

        .scheduleErrorTipItem {
            color: #FE545F;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.copyPopover {
    padding-top: 5px;
    padding-left: 5px;
    width: 100px;

    .ant-popover-inner-content {
        padding: 2px 0;
    }

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .copyItem {
        cursor: pointer;
        padding: 0 4px;
        color: #313131;
    }

    .copyItem:hover {
        background: rgba(0, 104, 178, 0.0500) !important;
        color: #0068B2
    }
}