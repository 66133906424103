$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.increaseAndLossSituationContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        position: relative;
        z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .downloadIcon1 {
            position: absolute;
            top: 2px;
            left: calc(40% - 36px);
        }

        .downloadIcon2 {
            position: absolute;
            top: 2px;
            left: calc(80% - 36px);
        }
    }

    .emptyContent {
        width: 100%;
        height: 270px;
        @include flex-c-c;
    }

    .increaseAndLossSituationContentBox {
        margin-top: 16px;
        // min-height: 290px;
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: space-between;

        .emptyContent {
            width: 100%;
            height: 140px;
            @include flex-c-c;
        }

        .myFormTable .ant-table-tbody>tr>td {
            padding: 8px;
        }

        .increaseAndLossSituationTableBox {
            width: calc(40% - 20px);
            height: 100%;
            margin-right: 20px
        }

        .increaseAndLossSituationTextBox {
            width: 20%;
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // justify-content: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            flex-wrap: wrap;

            .lostBox {
                width: 100%;
                // height: 80px;
                margin-top: 10px;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 104, 178, 0.15) 100%);
                border-radius: 4px 4px 4px 4px;
                padding: 10px;

                span {
                    font-weight: bold;
                }
            }

            .addBox {
                width: 100%;
                // height: 80px;
                margin-top: 10px;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(97, 212, 173, 0.15) 100%);
                border-radius: 4px 4px 4px 4px;
                padding: 10px;

                span {
                    font-weight: bold;
                }
            }

            i {
                font-style: normal !important;
            }

            h3 {
                margin-bottom: 0;
                font-size: 12px !important;
                font-weight: bold;
                color: #313131;
            }
        }

    }
}