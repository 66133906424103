$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.salesAndCustomerAnalysisPopulationContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        position: relative;
        z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

    }

    .emptyContent {
        width: 100%;
        height: 254px;
        @include flex-c-c;
    }

    .salesAndCustomerAnalysisPopulationContentBox {
        // height: 290px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .salesAndCustomerAnalysisPopulationBox {
            height: 170px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 12px !important;

            #salesAndCustomerAnalysisPopulationBox2,
            #salesAndCustomerAnalysisPopulationBox1 {
                height: 140px;
                width: 320px;
            }

            .salesAndCustomerAnalysisPopulationItem {
                height: 170px;
                min-width: 220px;

                .titleBox {
                    width: 100%;
                    height: 30px;
                    font-size: 18px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #0068B2;
                    text-align: center;
                }

                .numberBox {
                    height: 140px;
                    width: 100%;
                    font-size: 40px;
                    font-family: Century Gothic-Regular, Century Gothic;
                    font-weight: bold;
                    color: #FEC054;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .salesAndCustomerAnalysisPopulationTipItem {
            width: 100%;
            display: flex;
            // align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #535353;
            margin-top: 8px;
            display: flex;
            
            .numberSpan{
                color: #003666;
                font-weight: bold;
            }

            i {
                display: inline-block;
                width: 5px;
                height: 5px;
                background: #D8D8D8;
                border-radius: 50%;
                margin-right: 6px;
                margin-top: 7px;
            }
        }

    }
}

.salesAndCustomerAnalysisPopulationPopover {
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 2px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        max-width: 200px;
        padding: 8px 5px 8px 8px;
    }

    .salesAndCustomerAnalysisPopulationPopoverBox {
        font-size: 14px;
        color: #313131;

        .title {
            font-weight: bold;
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 140px;
            margin-top: 2px;

            span {
                display: inline-block;
                font-weight: bold;
            }
        }

    }
}