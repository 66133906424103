$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.caseCardContent {
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EFEFEF;
    position: relative;

    .tagsBox {
        height: 25px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amountBox {
            font-size: 12px;
            font-weight: normal;
            color: #9E9E9E;

            span {
                font-size: 14px;
                color: #0068B2;
                font-weight: bold;
            }
        }
    }

    .infoValue {
        @include flex;
        @include flex-j-s-b;
        // width: 640px;
        flex-wrap: wrap;

        .title {
            font-size: 14px;
            font-weight: 700;
            // width: 620px;
            // width: calc(100% - 50px);
            // 文字一行省略号显示
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;

            &:hover {
                color: #0068B2;
            }
        }

        .item {
            @include flex;
            @include flex-a-c;
            width: 50%;
            margin-top: 12px;

            .label {
                width: 50px;
                display: inline-block;
                text-align: justify;
                text-align-last: justify;
                margin-right: 10px;
                font-size: 12px;
                font-weight: normal;
                color: #9E9E9E;
                flex: none;
            }

            .value {
                width: calc(100% - 70px);
                font-size: 12px;
                color: #535353;
                // 文字一行省略号显示
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

            }
        }
    }

    .icon {
        @include flex;

        .trashSvg {
            margin-top: 2px;
            margin-left: 8px;
            cursor: pointer;
        }
    }
}

.sharesTip {
    z-index: 99;

    // .ant-tooltip-arrow{
    //     width: 14px;
    //     height: 14px;
    // }
    .ant-tooltip-inner {
        min-height: 20px;
        max-height: 50vh;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 4px;
        user-select: none;
        @include tooltipScrollbar;
        overflow-y: auto;
    }
}