$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.compositionOfSalesRevenueContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        // position: relative;
        // z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .yearSelectBox {
            position: absolute;
            bottom: 10px;
            right: 10px;
            z-index: 20;
        }

    }

    .emptyContent {
        width: 100%;
        height: 270px;
        @include flex-c-c;
    }

    .compositionOfSalesRevenueContentBox {
        height: 270px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #compositionOfSalesRevenueContentBox {
            position: relative;
            height: 100%;
            width: calc(100% - 140px);
            z-index: 8;


            // ~div,
            // canvas {
            //     width: 100% !important;
            //     height: 100% !important;
            // }
        }

        .circleTitleBox {
            display: block;
            position: absolute;
            z-index: 10;
            color: #FFFFFF;
            font-size: 12px;
            width: 80px;
            text-align: center;
            left: calc(50% - 110px);
            user-select: none;
        }

        .compositionOfSalesRevenueTextBox {
            width: 220px;
            // min-height: 200px;
            position: absolute;
            top: 46px;
            right: 16px;
            background: linear-gradient(133deg, #F2F7FB 0%, #FFFFFF 100%);
            border-radius: 4px 4px 4px 4px;
            padding: 12px 0px 12px 12px;
            z-index: 12;

            .titleBox {
                font-size: 16px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #313131;
            }

            .textBox {
                font-size: 12px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #313131;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                // justify-content: space-between;
                margin-top: 4px;

                span {
                    font-size: 20px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 400;
                    color: #0068B2;
                    margin-left: 6px;
                    display: flex;
                    align-items: center;

                    p {
                        margin-bottom: 0px;
                        font-size: 12px;
                        margin-left: 6px;
                    }
                }

                .span1 {
                    color: #61D4AD;
                }
            }
        }

    }
}