$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.CapitalOperationContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .infoBox {
        padding-left: 10px;
        width: 100%;
        height: 100%;

        .typeListBox {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            // @include scrollbar;

            div {
                flex: none;
                font-size: 12px;
                font-family: PingFang SC-中等, PingFang SC;
                font-weight: normal;
                color: #9E9E9E;
                cursor: pointer;
                margin-right: 24px;
                display: flex;
                align-items: center;

                &:last-of-type {
                    margin-right: 0;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 8px;
                    height: 20px;
                    background: #EFEFEF;
                    border-radius: 18px 18px 18px 18px;
                    font-size: 10px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    margin-left: 8px;
                }
            }

            .active {
                color: #0068B2;

                span {
                    background: #61D4AD;
                    color: #fff;
                }
            }
        }

        .scrollbarsBox {
            height: 60px;
            width: 100%;
            // margin-bottom: 10px;
            display: flex;
            align-items: center;

            .rightIcon {
                margin-left: 6px;
            }

            .rightIcon {
                margin-right: 6px;
            }
        }

        .ipoBox {
            width: 100%;
            // height: 100px;
            background: white;
            border-radius: 10px;
            border: 1px solid #EFEFEF;
            margin-top: 20px;

            .title {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #313131;
                padding: 16px 16px 0px 16px;
            }

            .ipoContent {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 0 16px 10px;
                .ipoItem {
                    width: 20%;
                    margin-top: 10px;
                    font-size: 12px;
                    font-family: PingFang SC-常规, PingFang SC;
                    font-weight: normal;
                    color: #535353;
                    display: flex;
                    align-items: center;

                    .ipoKey {
                        color: #9E9E9E;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    // .myDescriptions .ant-descriptions-item-label {
    //     display: table-cell;
    // }

    // .myDescriptions .ant-descriptions-row {
    //     display: table-row;
    //     ;
    // }

    // .myDescriptions .ant-descriptions-item-content {
    //     display: table-cell;
    // }
    .myDescriptions .ant-descriptions-row:last-of-type {
        .ant-descriptions-item-content:last-of-type {
            flex: none;
            width: calc(66.66% - 170px);
        }
    }
}

