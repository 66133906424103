$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.reviseContent {
    .title {
        font-size: 14px;
        font-weight: 500;
        color: #313131;
        margin-bottom: 10px;
        .anticon{
            font-size: 12px;
            cursor: pointer;
        }
    }

    .btnBox {
        @include flex-c-c;
        flex-direction: row;
        margin: 20px 0 10px 0;
        width: 100%;

        .ant-btn {
            margin-left: 0;

            &:first-of-type {
                margin-right: 15px;
            }
        }

    }
}