$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.followDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        padding: 24px 0 5px 0;
        overflow-x: hidden;
    }

    .noData {
        width: 100%;
        height: 100%;
        @include flex-c-c;
    }

    .searchBox {
        @include flex;
        @include flex-j-s-b;
        margin-bottom: 10px;
        padding: 0 24px;

        .iconBox {
            @include flex;

            .iconItem {
                user-select: none;
                font-size: 14px;
                color: #9E9E9E;
                cursor: pointer;
            }
        }

        .selectItem {
            text-align: left;

            .ant-select-selector {
                padding: 0 10px 0 0;
            }
        }
    }

    .cardBox {
        padding: 0 24px;
    }

    .loadingMoreDivider {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0;
        padding: 0 20px;
    }
}