$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.homeContent {
    @mixin bg-br {
        background: #fff;
        border-radius: 10px;
    }

    height: 100%;
    width: 100%;
    @include flex;
    @include flex-j-s-b;

    .leftBox {
        width: calc(70% - 20px);
        height: 100%;

        .leftTop {
            height: 250px;
            width: 100%;
            margin-bottom: 20px;
            @include bg-br;
            padding: $mainPadding;
            padding-bottom: 0;

            .ant-carousel .slick-dots {
                position: relative !important;
                bottom: -12px !important;
            }

            .ant-carousel .slick-dots li {
                width: 24px !important;
                height: 2px;
            }

            .ant-carousel .slick-dots li button {
                width: 24px !important;
                height: 2px;
                background: #D8D8D8;
                border-radius: 1px 1px 1px 1px;
                opacity: 1;
            }

            .ant-carousel .slick-dots li.slick-active button {
                background: #0068B2;
            }

            .creatCardBox {
                // @include flex;
                height: 170px;
                min-height: 165px;
                user-select: none;
                cursor: pointer;
                padding-top: 4px;
                position: relative;

                .ant-empty-image {
                    height: 130px;
                }

                .ant-empty-description {
                    color: #9E9E9E;
                }

                .creatCard {
                    flex: none;
                    box-sizing: border-box;
                    // width: calc(33.3% - 10px);˝
                    width: calc(100% - 20px);
                    margin-left: 10px;
                    height: 170px;
                    margin-top: 4px;
                    // height: 100%;
                    padding: 15px 20px 0px 20px;
                    border-radius: 10px;
                    @include flex;
                    flex-direction: column;
                    justify-content: start;
                    position: relative;
                    transition: transform .2s, box-shadow .2s;

                    &:last-of-type {
                        margin-right: 0px;
                    }

                    &:hover {
                        transition: transform .2s, box-shadow .2s;
                        transform: translateY(-4px);
                        box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);
                    }

                    .title {
                        font-size: 20px;
                        font-weight: 600;
                        z-index: 9;
                    }

                    .text {
                        font-size: 12px;
                        font-weight: 600;
                        z-index: 9;
                    }

                    .iconBox {
                        @include flex;
                        @include flex-j-s-b;

                        // margin-top: 10px;
                        .iconS {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            font-size: 90px;
                            opacity: 0.2;

                            svg {
                                width: 100px;
                                height: 85px;
                                position: absolute;
                                bottom: 0;
                                right: 5px;
                            }
                        }
                    }
                }

                .slick-track {
                    margin-left: unset !important;
                    margin-right: unset !important;
                }

                .slick-dots,
                .slick-prev,
                .slick-next {
                    display: none !important;
                }

                .carouselIcon {
                    font-size: 40px;
                    color: #D8D8D8;
                    position: absolute;
                    top: calc(50% - 20px);
                    cursor: pointer;
                    opacity: 0.5;
                    overflow: hidden;

                }

                .carouselIcon:hover {
                    opacity: 1
                }

                .LeftOutlined {
                    left: -20px;

                    svg {
                        position: relative;
                        right: 4px
                    }
                }

                .RightOutlined {
                    right: -20px;

                    svg {
                        position: relative;
                        left: 4px
                    }
                }

            }

        }

        .leftBottom {
            height: calc(100% - 270px);
            width: 100%;
            @include bg-br;
            padding: $mainPadding 0;

            .leftBottomContent {
                width: 100%;
                height: calc(100% - 20px);
                @include flex;

                .mapBox {
                    width: 60%;
                    height: 100%;
                    // background: red;
                    @include flex-c-c;

                    .map {
                        width: 100%;
                        // background: pink;
                    }
                }

                .menuInfoBox {
                    width: 40%;
                    height: 100%;
                    @include flex-c-c;
                    flex-wrap: wrap;
                    padding: 40px 20px;

                    .comContent {
                        min-height: 50%;
                        width: 100%;
                        max-height: 100%;
                        overflow: auto;

                        .comTitle {
                            font-size: 16px;
                            font-weight: 500;
                            color: #0068B2;
                            position: relative;

                            .line {
                                position: absolute;
                                width: 20px;
                                height: 2px;
                                bottom: -3px;
                                left: 22px;
                                background-color: #0068B2;
                            }
                        }

                        .comInfo {
                            margin-top: 10px;
                            font-size: 14px;
                            font-family: PingFang SC-常规, PingFang SC;
                            font-weight: normal;
                            color: #535353;
                        }
                    }

                    .childrenBox {
                        width: 100%;
                    }

                    .childrenItem {
                        width: 100%;
                        display: flex;

                        .iconBox {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                            border-radius: 50%;
                            text-align: center;
                            // font-size: 1rem;
                            @include flex-c-c;
                            flex: none;
                        }

                        .content {
                            margin-top: 5px;

                            .title {
                                font-size: 12;
                                color: #222222;
                                line-height: 14px;
                                // display: flex;
                            }

                            .childrenContent {
                                padding: 10px 0 15px 0;
                                font-size: 12px;
                                font-weight: 400;
                                color: #535353;
                                border-bottom: 1px dashed #D8D8D8;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }

        }
    }

    .rightBox {
        width: 30%;
        height: 100%;

        .rightTop {
            height: calc(45% - 10px);
            width: 100%;
            // margin-bottom: 20px;
            @include bg-br;
            padding: $mainPadding 0;
        }

        .line {
            height: 10px;
            @include flex-c-c;
            user-select: none;
            margin: 5px 0;

            .moveBox {
                width: 40px;
                background: #53535364;
                color: #D8D8D8;
                text-align: center;
                height: 6px;
                border-radius: 5px;

                &:hover {
                    background: #535353;
                }
            }

            .activeMove {
                background: #535353;
            }
        }

        .rightBottom {
            height: calc(55% - 10px);
            width: 100%;
            @include bg-br;
            // padding: $mainPadding;
        }
    }
}