$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.pathDivContent {
    width: 100%;
    height: 70px;
    @include flex-c-c;

    .lineNodesBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        // overflow-y: auto;
        // @include scrollbar;
        .nodeItem {
            flex: none;
            // margin-right: 10px;
            display: flex;
            align-items: center;

            .personItem {
                width: 48px;
                height: 48px;
                @include flex-c-c;
                // background: #FEC054;
                text-align: center;
                border-radius: 50%;
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: normal;
                // color: #FFFFFF;
            }

            .companyItem {
                width: 70px;
                @include flex-c-c;
                padding: 0 5px;
                height: 46px;
                // background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #D8D8D8;
            }

            .rightLine,
            .leftLine {
                width: 70px;
                height: 2px;
                margin: 0 10px;
                background: #D8D8D8;
                position: relative;
                @include flex-c-c;

                .typeTip {
                    margin-top: -30px;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #0068B2;
                    line-height: 14px;
                }

                .rightLineIcon {
                    position: absolute;
                    right: -10px;
                    top: -6px;
                    font-size: 14px;
                    color: #D8D8D8;
                }

                .leftLineIcon {
                    position: absolute;
                    left: -10px;
                    top: -6px;
                    font-size: 14px;
                    color: #D8D8D8;
                }
            }

        }
    }
}

.tipContentBox {
    // color: #D8D8D8;
    font-size: 14px;

    .tipContentText {
        font-size: 12px;
        padding-left: 10px;

        span {
            margin-left: 5px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1700px) {
    .pathDivContent {
        .lineNodesBox {
            .nodeItem {

                .personItem {
                    width: 40px;
                    height: 40px;
                    font-size: 12px;

                }

                .companyItem {
                    width: 58px;
                    height: 40px;
                    padding: 0 2px;
                    font-size: 12px;
                }

                .rightLine,
                .leftLine {
                    width: 60px;
                    padding: 0 6px;
                }
            }
        }
    }
}