$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.CaseDetailsDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px
    }


    .detailsContent {
       
        padding: $mainPadding;
        width: 100%;

        .baseContent{
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #EFEFEF;
        }

        .detailsBox {
            width: 100%;
            @include flex;
            flex-wrap: wrap;
            
            .tagValue {
            margin-top: 12px;
            margin-left: 8px;
            }
            .detailItem {
                @include flex;
                align-items: center;
                width: 100%;
                margin-top: 10px;
                padding-left: 12px;
                
                &:nth-child(1) {
                    align-items: flex-start;
                }

                .detailKey {
                    width: 49px;
                    line-height: 21px;
                    margin-right: 12px;
                    font-size: 12px;
                    font-weight: normal;
                    color: #9E9E9E;
                    display: inline-block;
                    text-align: justify;
                    text-align-last: justify
                }

                .detailValue {
                    width: 462px;
                    font-size: 14px;
                    color: #535353;
                }
                .caseState {
                    width: 52px;
                    height: 18px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 18px;
                    border-radius: 2px;
                    background-color: #61D4AD;
                    color: #fff;
                }
            }
           .caseProgress {
            margin: 20px 12px;          
           }
        }
       
    }

    .ant-tag {
       
        height: 30px;
        margin-right: 8px;
        padding: 0 12px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        border-radius: 16px;
        background-color: #F7F7F7;
        border: 1px solid #F7F7F7;

        &:hover {
            background: #0068B2;
            color: #fff;
        }
   }
  
   
}


      