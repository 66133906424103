$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.subscribeListDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        padding: 0;
    }

    .noData {
        width: 100%;
        height: calc(100% - 75px);
        @include flex-c-c;
    }

    .subBox {
        width: 100%;
        height: calc(100% - 75px);

        .subCardBox {
            height: 100%;
            width: 100%;
        }

    }

    .subBtnBox {
        padding: 20px;

        .ant-btn {
            width: 100%;
            height: 35px;
            @include flex-c-c;
        }
    }

    .settingCard {
        &:last-of-type {
            margin-bottom: 0px;
        }

        margin: 20px;
        min-height: 70px;
        margin-bottom: 10px;
        background: #FBFBFD;
        border-radius: 5px;
        user-select: none;
        position: relative;

        .ant-card-body {
            padding: 10px;
            height: 100%;
            @include flex-c-c;
        }

        .settingImg {
            width: 80px;
            height: 100%;
            min-height: 70px;
            @include flex-c-c;

            .iconBox {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: #0068B2;
                @include flex-c-c;
                font-size: 30px;
                color: white;

                img {
                    width: 40%;
                    height: 40%;
                }
            }
        }

        .settingContent {
            width: calc(100% - 80px);
            height: 100%;

            .top {
                @include flex;
                @include flex-j-c;
                font-size: 14px;

                .typeBox {
                    font-weight: 600;
                    color: #0068B2;
                }

                .textContent {
                    width: calc(100% - 120px);
                    color: #313131;
                }

                .iconBox {
                    width: 40px;
                    @include flex;
                    @include flex-j-s-b;
                    font-size: 16px;
                    padding-top: 2px;
                    // color: #0068B2;

                    .anticon {
                        cursor: pointer;
                    }
                }
            }

            .bottom {
                @include flex;
                width: 100%;
                font-size: 12px;
                margin-top: 10px;

                .valueItem {
                    width: 50%;
                    margin-right: 5px;
                    @include flex;

                    .label {
                        margin-right: 5px;
                        color: #9E9E9E;
                    }

                    .value {
                        color: #535353;
                    }
                }
            }
        }

    }
}