$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.myJudicialContainer {
    width: 100%;
    height: 100%;
    min-width: 544px;
    padding: 4px 16px 12px 16px;

    .disMyRangePickerContent {
        background: #fff !important;
    }

    .searchBox {
        width: 100%;
        height: 100%;
        @include flex;
        @include flex-j-s-b;
        flex-wrap: wrap;
        margin-bottom: 12px;

        .searchItem {
            &:nth-child(1) {
                width: 100%;
                margin-bottom: 2px;

                .value {
                    .ant-tag {
                        height: 30px;
                        margin-right: 8px;
                        padding: 0 10px;
                        text-align: center;
                        line-height: 30px;
                        font-size: 12px;
                        border-radius: 4px;
                        background-color: #F7F7F7;
                        border: 1px solid #F7F7F7;
                    }

                    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
                        color: #000;
                    }
                }
            }

            width: 50%;
            @include flex;
            @include flex-a-c;
            flex-wrap: wrap;
            margin-top: 12px;

            .label {
                width: 60px;
                margin-right: 10px;
                text-align: justify;
                text-align-last: justify;
                font-size: 14px;
                font-weight: 700;
            }

            .value {
                margin-left: 5px;
                font-size: 10px;
                font-weight: normal;
                border-radius: 10px;
                color: #535353;

            }


            .ant-select-selection-item {
                font-size: 12px;
            }

            .ant-select-selection-item-content {
                margin-right: 0;
            }
        }


    }

    .ant-tag-checkable-checked {
        background-color: #0068B2 !important;
    }

    .ant-select-arrow {
        /*取消默认箭头开始*/
        appearance: none;
    }

    .ant-select-selection-overflow {
        width: 220px;
        flex-wrap: nowrap;
        // 文字一行省略号显示
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .ant-picker-range {

        width: 250px;
    }

    .ant-input-group {
        width: auto;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff !important;
    }

    .ant-select-disabled.ant-select-multiple {
        background-color: #fff !important;
    }

    .ant-input-affix-wrapper-disabled {
        background-color: #fff !important;
    }

    .ant-select-selection-item {
        color: rgba(0, 0, 0, .85);
    }


    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        background-color: #f5f5f5 !important;
        border-color: rgba(0, 0, 0, .06) !important;
        color: rgba(0, 0, 0, .85);
    }



    .ant-input-group {

        .ant-input[disabled] {
            background-color: #fff;

            &:nth-child(1) {
                border-left-color: #d9d9d9 !important;
                border-right-color: #fff !important;

                &:hover {
                    border-left-color: #d9d9d9 !important;
                    border-right-color: #fff !important;
                }
            }

            &:nth-child(3) {
                border-left-color: #fff !important;
                border-right-color: #fff !important;

                &:hover {
                    border-left-color: #fff !important;
                    border-right-color: #fff !important;
                }
            }

            &:hover {
                border-top-color: #d9d9d9 !important;
                border-bottom-color: #d9d9d9 !important;
            }
        }

        .numberImput {
            border: 1px solid #0068B2 !important;
        }

        .ant-input {
            &:hover {
                border: 1px solid #0068B2 !important;
            }

            &:focus {
                border: 1px solid #0068B2 !important;
            }
        }


    }

    .ant-picker-disabled {
        background-color: #fff !important;
    }

    .ant-select-arrow {
        display: inline-block;
        vertical-align: -10%;
    }

}

.subjectScopeSearchPoP {
    .ant-select-item {
        width: 100%;
    }


    .ant-select-item-option-content {
        width: 100%;
        display: flex;
        align-items: center;

        .textItem {
            color: rgba(0, 0, 0, .85);
            max-width: calc(100% - 30px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ant-tag {
            margin: 0 0 0 5px;
            padding: 0 1px;
            font-size: 12px;
            transform: scale(0.8);
            font-weight: normal !important;
            border-radius: 4px 4px 4px 4px;
            height: 20px;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }
    }
}