$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.riskVerification {
    width: 100%;
    height: calc(100% - 10px);
    margin-top: 10px;

    .configContent {
        padding: 0 12px;
        // height: 100%;
        width: 100%;

        .dateBox {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dateSpan {
                font-size: 12px;
                color: #9E9E9E;

                .numSpan {
                    font-weight: bold;
                    font-size: 16px;
                    color: #0068B2;
                }

                .anticon {
                    font-size: 16px;
                    color: #0068B2;
                    margin-left: 6px;
                }
            }
        }

        .loadingTextBox {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // height: 30px;
            margin-top: 20px;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #313131;
            font-weight: bold;

            .anticon {
                color: #0068B2;
            }

            .imgItemBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;

                img {
                    display: block;
                    height: 24px;
                    width: auto;
                }
            }
        }

        .configItemsBox {
            width: 100%;
            height: calc(100% - 80px);
            display: flex;
            flex-wrap: wrap;
            user-select: none;

            .configItemBox {
                width: 25%;
                aspect-ratio: 2.5 / 1;
                margin-top: 20px;

                &:nth-of-type(1) {
                    margin-top: 0px;
                }

                &:nth-of-type(2) {
                    margin-top: 0px;
                }

                &:nth-of-type(3) {
                    margin-top: 0px;
                }

                &:nth-of-type(4) {
                    margin-top: 0px;
                }

                .configItemCard {
                    width: 100%;
                    height: calc(100% - 11px);
                    margin-bottom: 8px;
                    background: rgba(0, 104, 178, 0.03);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .itemIconBox {
                        height: 50%;
                        aspect-ratio: 1 / 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: #D8D8D8;

                        img {
                            display: block;
                            height: 100%;
                            width: 100%;
                            pointer-events: none;
                        }
                    }

                    .itemInfoBox {
                        width: 100px;
                        height: 60%;
                        margin-left: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;

                        .numberBox {
                            width: 100%;
                            font-weight: 400;
                            font-size: 12px;
                            color: #9E9E9E;
                            height: 26px;
                            display: flex;
                            align-items: baseline;
                            line-height: 26px;

                            span {
                                display: block;
                                height: 26px;
                                font-size: 24px;
                                line-height: 26px;
                                color: #313131;
                                margin-right: 2px;
                                font-weight: bold;
                            }
                        }

                        .tagBox {
                            width: 100%;
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;

                            span {
                                flex: none;
                                font-size: 12px;
                                color: #313131;
                                font-weight: bold;
                            }

                            .ant-tag {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0 4px;
                                margin-left: 4px;
                                border-radius: 3px 3px 3px 3px;
                                border: 1px solid #9E9E9E;
                                line-height: normal !important;
                                color: #9E9E9E;
                                font-weight: 500;
                            }
                        }
                    }

                    .itemLineBox {
                        display: none;
                    }
                }

                .configItemLine {
                    width: 100%;
                    height: 3px;
                    background-color: #D8D8D8;
                }

            }

            .configActiveItemBox {
                .configItemLine {
                    width: calc(100% - 2px);
                    margin-right: 2px;
                }

                .configItemCard {
                    position: relative;

                    .itemLineBox {
                        display: block !important;
                        position: absolute;
                        top: 20%;
                        right: 0;
                        display: none;
                        width: 1px;
                        background-color: #D8D8D8;
                        height: 60%;
                    }
                }
            }

            .configLoadingActiveItemBox {
                .itemIconBox {
                    background: linear-gradient(to right, #61D4AD 50%, #D8D8D8 50%) !important;
                }

                .configItemLine {
                    background: linear-gradient(to right, #61D4AD 50%, #D8D8D8 50%) !important;
                }

            }

            .configEndActiveItemBox {

                .itemInfoBox {
                    .numberBox {
                        color: #61D4AD !important;

                        span {
                            color: #61D4AD !important;
                        }
                    }

                    .tagBox {
                        span {
                            color: #61D4AD !important;
                        }

                        .ant-tag {
                            color: #61D4AD !important;
                            border: 1px solid #61D4AD !important;
                        }
                    }
                }

                .itemIconBox {
                    background: #61D4AD !important;
                }

                .configItemLine {
                    background: #61D4AD !important;
                }

            }
        }

    }

    .reportTablePagination{
        // float: right;
        font-size: 12px !important;
        display: flex;
        align-items: center;
        justify-content: end;

        .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next{
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 26px !important;
            min-height: 26px !important;
            height: 26px !important;
            width: 26px !important;
            button{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .tableDetailBtn{
        color: #0068B2;
        cursor: pointer;
        user-select: none;
    }
}