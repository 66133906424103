$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.overviewPopulationContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        position: relative;
        z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }
    }

    .emptyContent {
        width: 100%;
        height: 254px;
        @include flex-c-c;
    }

    .overviewPopulationBox {
        display: flex;
        width: 100%;
        // height: 320px;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;

        .overviewPopulationItem {
            height: 240px;
            min-width: 220px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .titleBox {
                width: 100%;
                height: 30px;
                font-size: 18px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #0068B2;
                text-align: center;

                .titleTextBox {
                    font-size: 12px;
                    color: #9e9e9e;
                }
            }

            .numberBox {
                height: 50px;
                width: 100%;
                font-size: 40px;
                font-family: Century Gothic-Regular, Century Gothic;
                font-weight: bold;
                color: #FEC054;
                display: flex;
                align-items: baseline;
                justify-content: center;

                span {
                    font-size: 12px;
                    color: #313131;
                    margin-left: 5px;
                }
            }
        }

        #overviewPopulationBox1,
        #overviewPopulationBox2,
        #overviewPopulationBox3 {
            position: relative;
            height: calc(100% - 30px);
            width: 100%;
            z-index: 8;
            display: flex;
            align-items: center;
            justify-content: center;

            // background: #313131;
            ~div,
            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .overviewPopulationTipItem {
        width: 100%;
        display: flex;
        // align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #535353;
        margin-top: 8px;

        i {
            display: inline-block;
            width: 5px;
            height: 5px;
            background: #D8D8D8;
            border-radius: 50%;
            margin-right: 6px;
            margin-top: 7px;
        }

        .numberSpan {
            color: #003666;
            font-weight: bold;
        }
    }

}