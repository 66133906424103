$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.judicialCasesContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;
    min-width: 1210px;

    // min-height: 850px;
    .header {
        @include flex;
        align-items: center;

        .warningIcon {
            margin-left: 16px;
            margin-right: 8px;
            font-size: 16px;
            color: #0068B2;
        }
    }

    .dateBox {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        margin-top: 15px;
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .infoBox {
        width: 100%;
        height: 100%;
        padding-left: 10px;

        .topBox {
            @include flex;
            height: 95px;
            width: 100%;
            background: rgba(0, 104, 178, 0.0500);
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 20px;

            .overview {
                margin: 16px;
                font-weight: 700;
            }

            .content {
                @include flex;
                @include flex-a-c;
                width: calc(100% - 140px);
                margin-left: 50px;

                .item {

                    // margin: calc(10% - 35px);
                    &:nth-of-type(1) {
                        width: 20%;
                    }

                    &:nth-of-type(2) {
                        width: 40%;
                    }

                    &:nth-of-type(3) {
                        width: 25%;
                    }

                    &:nth-of-type(4) {
                        width: 15%;
                    }

                    text-align: center;

                    .num {
                        font-size: 30px;
                        font-weight: 700;
                    }

                    .data {
                        font-size: 14px;
                        color: #9E9E9E;
                    }
                }
            }
        }

        .optionsBox {
            height: 290px;
            @include flex;
            @include flex-j-s-b;
            margin-bottom: 20px;

            .optionsCardBox {
                height: 100%;
                width: calc(50% - 10px);
                border-radius: 10px 10px 10px 10px;
                border: 1px solid #EFEFEF;
                position: relative;

                .cardTitle {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #313131;
                    user-select: none;
                }

                .judicialAnalysiNoData {
                    width: 100%;
                    height: 100%;
                    @include flex-c-c;
                }
            }
        }

        .cardBox {
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #EFEFEF;
            padding-bottom: 8px;


            .iconValue {
                @include flex;
                justify-content: flex-end;
                text-align: center;
                margin: 16px;

                .addIcon {
                    font-size: 18px;
                    margin-right: 16px;
                }
            }

            .CaseCard {
                @include flex;
                @include flex-j-s-b;
                flex-wrap: wrap;
                padding: 0 16px;
            }

            .myPaginationSite {
                position: absolute;
                bottom: 0;
                right: 0;
                margin-right: 16px;
                //  margin-bottom: 16px

            }

            .paginationBox {
                @include flex;
                align-items: flex-end;
                flex-direction: row-reverse;
                margin-right: 16px;

                .myPagination {

                    .ant-pagination-item {
                        a:hover {
                            color: #0068B2;
                            // background: rgba(0, 104, 178, 0.09803921729326248);
                        }
                    }

                    .ant-pagination-item-active {
                        a {
                            color: #0068B2;
                            background: rgba(0, 104, 178, 0.09803921729326248);
                        }
                    }
                }


            }


            .noData {
                width: 100%;
                height: 300px;
                @include flex-c-c;
            }

        }


    }
}