$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.subscribeContent {
    width: 100%;
    height: 100%;

    .ant-typography {
        margin-bottom: 0;
    }

    .noData {
        width: 100%;
        height: 100%;
        @include flex-c-c;
    }

    .iconBox {
        font-size: 18px;
        height: 26px;

        .anticon {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .searchBox {
        @include flex;
        @include flex-j-s-b;
        margin-bottom: 10px;
    }

    .subscriptionInformation {
        user-select: none;
        height: 100%;
        padding: 0 20px;

        .headerDate {
            display: inline-block;
            margin-right: 5px;
            // width: 120px;
        }

        .extraText {
            font-size: 14px;
            color: #313131;
            cursor: pointer;
        }

        .ant-collapse-header-text {
            width: calc(100% - 90px);
        }

        .ant-collapse {
            border: none;
            background-color: #fff;
        }

        .ant-collapse-arrow {
            right: 0px !important;
        }

        .ant-collapse-item {
            border: none;
        }

        .ant-collapse-header {
            padding: 12px 20px 12px 0px;
            border: none;
        }

        .ant-collapse-content {
            border: none;

        }

        .ant-collapse-content-box {
            padding: 0 2px 0 16px;
        }

        .panelItem:last-of-type {
            .subscriptionText {
                padding-bottom: 0px;
                border-bottom: none;
                margin-bottom: 0px;
            }
        }

        .panelItem {
            @include flex;

            .ant-tag {
                height: 20px;
                border-radius: 15px;
                box-sizing: border-box;
                line-height: 17px;
                background: #fff;
                margin-top: 2px;
            }

            .subscriptionText {
                width: 100%;
                padding-bottom: 12px;
                border-bottom: 1px dashed #D8D8D8;
                margin-bottom: 12px;
                font-size: 14px;
                font-family: PingFang SC-常规, PingFang SC;
                font-weight: normal;
                color: #535353;
            }
        }
    }

    .loadingMoreDivider {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 0;
        padding: 0 20px;
    }

    .loadMoreBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        &:hover {   
            color: #006AB2;
        }
    }

    .loadingBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #006AB2;

    }
}

@media screen and (max-width:1200px) {
    .subscribeContent {
        .subscriptionInformation {
            .extraText {
                display: none;
            }

            .ant-collapse-header-text {
                width: 100%;
            }
        }
    }
}