$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.taxCollectionDetailsContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    min-height: 310px;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;
        position: relative;
        z-index: 9;

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

    }

    .emptyContent {
        width: 100%;
        height: 254px;
        @include flex-c-c;
    }

    .taxCollectionDetailsContentBox {
        margin-top: 8px;
        // height: 290px;
        width: 100%;

        .searchBox {
            min-height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .inputBox {
                // @include flex-c-c;
                display: flex;
                align-items: center;
                // width: 460px;
                margin-right: 24px;
                min-width: 230px;
                margin-bottom: 8px;

                .label {
                    font-size: 14px;
                    color: #313131;
                    min-width: 60px;
                    flex: none;
                }

                .ant-select {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                    box-sizing: border-box;
                }

                .ant-input {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                    box-sizing: border-box;
                }

                .MyRangePickerContent,
                .ant-picker-range {
                    border: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.0800) !important;
                    box-sizing: border-box;
                }

                .MyRangePickerContentFocus {
                    box-shadow: none;
                }
            }
        }

        .tableContentBox {
            width: 100%;
            height: calc(100% - 40px);
        }

        .emptyContent {
            height: 100% !important;
        }

        .paginationBox {
            margin-top: 13px;
            display: flex;
            flex-direction: row-reverse;
        }
    }
}

.selectTextItem {
    color: #313131;
}