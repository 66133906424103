$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.mySidebarContent {
    height: calc(100% - 40px);
    width: 190px;
    background: #fff;
    margin: 20px;
    margin-right: 0px;
    border-radius: 10px;
    // padding: $mainPadding;
    user-select: none;

    .sidebarBox{
        padding: 0 $mainPadding;
    }

    .sidebarItem {
        &:first-of-type {
            margin-top: 0px;
        }

        margin-top: 5px;

    }

    .sidebarTitle {

        // width: 100%;
        &:hover {
            color: #0068B2;
        }


        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        color: #313131;
        @include flex;
        @include flex-a-c;

        .titleIcon {
            // width: 14px;
            // display: block;
            margin-right: 5px;
            color: #0068B2;
            display: flex;
            div{
                @include flex-c-c;
            }
            svg{
                width: 14px;
                height: 14px;
            }
        }
    }

    .disChildren{
        cursor:not-allowed;
        font-size: 12px;
        color: #9E9E9E;
        padding-left: 20px;
        margin-top: 5px;
    }
    .sidebarChildren {
        &:hover {
            color: #0068B2;
        }

        cursor: pointer;
        font-size: 12px;
        color: #535353;
        padding-left: 20px;
        margin-top: 5px;
    }

    .activeMenu {
        color: #0068B2;
    }
}