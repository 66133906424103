$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.CaseDialogDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: $mainPadding;
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .ant-form-item-label {
    width: 100%;
    label {
      width: 100%;
    }
  }
  .dynamic-delete-button {
    position: relative;
    top: 33px;
  }
  .dynamic-delete-button1 {
    position: relative;
    right: -537px;
    top: -108px;
  }
  .ant-space-horizontal {
    padding-left: 16px;
  }

  .ant-form-item {
    flex-direction: row;
  }

  .ant-form-item-required {
    font-size: 14px;
  }
  .ant-select-selection-overflow {
    width: 220px;
    flex-wrap: nowrap;
    // 文字一行省略号显示
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .noRelatedCase {
    @include flex;
    width: 100%;
    height: 30px;
    color: #bfbfbf;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 23px;
  }
}
