$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.caseTimelineContent {
    .timelineContent {
        width: 100%;
        display: flex;
        margin-left: 12px;
        margin-bottom: 11px;

        .timeLine {
            width: 5%;
            display: flex;

            .lineBox {
                width: 28px;
                height: 100%;
                margin-top: 6px;

                .iconBox {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #0068B2;
                    @include flex-c-c;

                    .icon {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                    }

                    .iconExpend {
                        background-color: #0068B2;
                    }
                }

                .line {
                    width: 1px;
                    background: #0068B2;
                    min-height: 20px;
                    margin-left: 5.5px;
                    height: 100%;
                }
            }

            .time {
                font-weight: 400;
                color: #535353;
                font-size: 14px;
            }
        }

        .contentDetail {
            font-size: 14px;

            .contentDetailTime {
                width: 90px;
                display: inline-block;
                margin-right: 16px;
                color: #535353;
            }

            .title {
                display: inline-block;
                color: #0068B2;
                margin-bottom: 10px;
                cursor: pointer;
            }

            .detailIcon {
                margin-left: 4px;
                color: #0068B2;
                cursor: pointer;
            }

            .openUrl {
                color: #0068B2;
                margin-left: 20px;
                cursor: pointer;
            }

            .detailsMessage {
                @include flex;
                justify-content: start;
                width: 438px;
                margin-left: 99px;

                .details {
                    margin-left: 2px;
                    width: 381px;
                    font-size: 12px;
                    color: #9E9E9E;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .moreDetail {
                    font-size: 12px;
                    color: #0068B2;
                    cursor: pointer;
                }
            }
        }

    }


}


.myModal {
    .content {
        @include flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 12px;

        .contentValue {
            @include flex;
            margin-bottom: 12px;
            align-items: center;

            .detailKey {
                width: 50px;
                margin-right: 5px;
                font-weight: normal;
                color: #9E9E9E;
                display: inline-block;
                text-align: justify;
                text-align-last: justify
            }

            .detailValue {
                width: calc(100% - 60px);
                color: #535353;
                display: inline-block;

            }

        }


    }


    .ant-modal-body {
        padding: 24px 16px !important;
    }


}