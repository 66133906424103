$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondBusinessInformationContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;
    padding-right: 0px;

    .iconBox {
        font-size: 14px;
        font-weight: 500;
        color: #0068B2;
        cursor: pointer;
        user-select: none;

        .anticon {
            margin-right: 5px
        }

        &:hover {
            text-decoration: underline
        }
    }


    .infoBox {
        padding-left: 10px;
        height: calc(100% - 10px);
        width: calc(100% - 20px);
        margin-right: 20px;
    }

    .headerTitleContent {
        margin-right: 20px;
    }
}


.bondHistoryBusinessInformationDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding-bottom: 14px;
        padding-top: 14px;
    }

    .paginationBox {
        position: absolute;
        bottom: 8px;
        right: 0;
        margin-top: 13px;
        display: flex;
        flex-direction: row-reverse;
    }
}