$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.uploadModal {
    .ant-modal {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 20px;
        }

        .ant-modal-close-x {
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 20px;
            padding-bottom: 0;
        }

    }

    .uploadFileModalContent {

        .uploadInfo {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #787878;
            margin-bottom: 15px;
        }

        .uploadTip {
            font-size: 14px;
            font-family: PingFang SC-粗体, PingFang SC;
            font-weight: normal;
            color: #313131;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .anticon{
                font-size: 20px;
                color: #9E9E9E;
            }
        }
        .uploadBtnBox{
            margin: 20px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .noBg{
                margin-right: 24px;
            }
        }

        .upLoadFileModalTableRow{
            text-align: center;
        }
    }
}