$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.reportCard {
    flex: none;
    width: 160px;
    height: 100px;
    // margin-left: 24px;
    padding-top: 10px;
    position: relative;

    .reportIcon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .reportType {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #313131;
    }

    .reportSize {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #9E9E9E;
    }

    .progressBox {
        width: 100%;
        @include flex-c-c;

        .ant-progress {
            width: 100px;
        }

        .ant-progress-bg {
            height: 2px !important;
        }
    }

    .bgBox {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 104, 178, 0.4);
        align-items: center;
        justify-content: center;

        .bgBtnBox {
            width: 28px;
            height: 28px;
            background: #FFFFFF;
            border-radius: 50%;
            box-shadow: 0px 4px 10px 0px rgba(0, 104, 178, 0.2);
            font-size: 14px;
            color: #0068B2;
            margin-right: 16px;
            font-weight: bold;
            @include flex-c-c;
            cursor: pointer;
        }

        .bgBtnBox:last-of-type {
            margin-right: 0;
        }
    }

    &:hover {
        .bgBox {
            display: flex;
        }
    }
}

.ant-tooltip-hidden {
    display: none !important;
}

.ReportInfoTooltip {
    width: 410px;
    max-width: 410px;

    .reportIconContent {
        // padding: 10px;
        width: 100%;

        .itemBox {
            width: 100%;
            display: flex;
        }

        .keyBox {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #D8D8D8;
            flex: none;
            margin-right: 8px;
            // width: 50px;
        }

        .valueBox {
            font-size: 12px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }

        .ant-typography p,
        div.ant-typography {
            margin-bottom: 0;
        }
    }

    .sharesTip {
        padding-bottom: 5px;
    }
}