$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.noPaddingPopover {
    .ant-popover-inner-content {
        padding: 0px;
    }
}

.infoLoadingBox {
    padding: 10px;
    @include flex-c-c;
}

.infoIconContent {
    width: 100%;
    @include flex-c-c;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: auto;
    padding: 12px 16px;

    .ant-typography {
        margin-bottom: 0px;
    }

    @include scrollbar;

    .item:last-of-type {
        border-bottom: none;
        padding-bottom: 0px;
    }

    .item {
        width: 100%;
        // margin: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #D8D8D8;

        .iconBox {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 5px;
            color: #0068B2;
            font-size: 16px;

            .anticon {
                cursor: pointer;
            }

            .iconSvg {
                height: 16px;
                width: 16px;

                svg,
                div {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .top {
            @include flex;
            @include flex-j-s-b;
            font-size: 12px;
            color: #535353;
        }

        .info {
            margin-top: 10px;
            @include flex;
            align-items: center;

            .text {
                font-size: 12px;
                color: #535353;
            }

        }

        .tag {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .status {
                height: 22px;
                min-width: 50px;
                padding: 0 5px;
                text-align: center;
                border-radius: 10px;
                font-size: 10px;
                line-height: 22px;
                font-weight: normal;
                color: #FFFFFF;
                margin-right: 5px;
                flex: none;
            }

            .user {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                color: #0068B2;

                span {
                    margin-left: 1px;
                }
            }
        }
    }
}

.deleteIcon {
    color: #535353;
}

.deleteTip {
    z-index: 1031;
}

.deletePopconfirm {
    width: 180px;
    z-index: 1032;

    .ant-popover-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-btn-default {
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #D9D9D9;
            color: #535353;
        }

        .ant-btn-primary {
            background: #FE545F;
            border-radius: 4px 4px 4px 4px;
            border-color: #FE545F;
        }
    }
}

.editParagraphTooltip {
    max-width: 250px;

    .editParagraphTip {
        max-height: 225px;
        width: 238px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}