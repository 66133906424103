$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.userChangeDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        // padding-top: 20px;
        padding: 0px
    }

    .userTable {
        .ant-table-cell {
            height: 50px;
        }

        .ant-table-row {
            border-bottom: none !important;
        }

        .ant-table-tbody>tr>td {
            border-bottom: none !important;
        }

        .ant-select-selector {
            padding: 0;
        }
    }

    .userListBox {
        padding: 20px;

        .invitation {
            height: 30px;
            margin-bottom: 20px;
            @include flex;
            @include flex-j-s-b;

            .iconBox {
                font-size: 14px;
                font-weight: 500;
                color: #0068B2;
                cursor: pointer;
                user-select: none;

                // text-decoration:underline;
                .text {
                    border-bottom: 1px solid #0068B2;
                }

                .anticon {
                    margin-right: 5px
                }
            }
        }

        .searchBox {
            @include flex;
            @include flex-j-s-b;

            // margin-bottom: 20px;
            .ant-btn-primary {
                border-color: #0068B2;
                background: #0068B2;
            }

            .ant-btn-primary:hover {
                border-color: #0068b2df;
                background: #0068b2df;
            }
        }
    }

    .deleteIcon {
        cursor: pointer;

        &:hover {
            svg {
                fill: #0068B2;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            fill: #535353;
        }
    }

    .optionUserTag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 5px;

        .ant-avatar {
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.invitationModal {
    .content {
        padding: 40px 0;

        .ant-input {
            border-radius: 4px 0 0 4px !important;
            font-size: 14px;
            color: #313131;
            height: 40px;
        }

        .ant-input:hover {
            border-color: #0068B2;
        }

        .ant-input:focus {
            border-color: #0068B2;
        }

        .ant-input-search-button {
            border-color: #0068B2;
            background: #0068B2;
            border-radius: 0 4px 4px 0 !important;
        }

        .ant-btn-lg {
            font-size: 14px;
        }

        .tip {
            margin-top: 10px;
            font-size: 14px;
            font-weight: normal;
            color: #9E9E9E;
        }
    }
}