$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.myDivItem {
    // position: relative;
    width: 100%;
    display: inline-block;
    // padding-right: 10px;
    // user-select: none;
    word-wrap: break-word;
    word-break: break-word;

    .myItem {
        display: inline-block;
        width: 100%;

        .sourceIcon {
            height: 16px;
            width: 16px;
            margin-left: 5px;
            cursor: pointer;
            color: #313131;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transform: translateY(2px);

            div {
                height: 16px;
                width: 16px;
                @include flex-c-c;
                // margin-top: 3px;
            }

            svg {
                height: 16px;
                width: 16px;
            }
        }

        .editIcon {
            font-size: 16px;
            margin-left: 5px;
        }

        .iconSvg {
            margin-left: 5px;
            margin-right: 0;
            transform: translateY(2px);
            height: 16px;
            width: 16px;

            svg,
            div {
                height: 16px;
                width: 16px;
            }
        }

        .noHover {
            // display: inline-block;
            // width: 120px;
            display: none;
            // opacity: 0;
            cursor: default;
        }
    }
}