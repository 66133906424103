$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.detailsDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px
    }


    .detailsContent {
        padding: $mainPadding;
        width: 100%;

        .detailsBox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .fileUrl {
                color: #0068B2;
                cursor: pointer;
                font-size: 12px;
                width: 100%;
                user-select: none;
                margin-top: 8px;
                line-height: 14px;

                &:first-of-type {
                    margin-top: 0;
                }

                &:hover {
                    text-decoration: underline
                }
            }

            .detailImgBox {
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                padding-left: 12px;

                .ant-image {
                    margin-right: 20px;
                    margin-bottom: 10px;
                    width: 110px;
                    height: 110px;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .detailItem {
                margin-top: 10px;
                padding-left: 12px;
                flex: none;
                @include flex;
                align-items: center;

                .detailKey {
                    width: 60px;
                    margin-right: 12px;
                    flex: none;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: normal;
                    color: #9E9E9E;
                    display: inline-block;
                    text-align: justify;
                    text-align-last: justify
                }

                .detailValue {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: normal;
                    color: #535353;
                    white-space: pre-wrap;
                    word-break: break-all;
                }
            }
        }

        .itemFormUrlBox {
            width: 100%;
            border: 1px dashed #9E9E9E;
            margin: 10px 12px 0 12px;
            padding: 5px 10px;
            border-radius: 2px;

            .detailFileBox {
                display: flex;
                align-items: center;
                width: 100%;

                .detailUrl {
                    width: 60px;
                    color: #0068B2;
                    cursor: pointer;
                    flex: none;
                    font-size: 12px;
                    user-select: none;
                    display: inline-block;
                    text-align: justify;
                    text-align-last: justify
                }

                .disUrl {
                    color: #9E9E9E;
                    cursor: not-allowed;
                }

                .fileUrl {
                    width: auto;
                    margin-top: 0;
                    margin-left: 5px
                }
            }
        }


    }
}

.detailModal {
    .ant-modal {
        border-radius: 10px;

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 0;

        }

        #detailHtmlBox {
            padding: 20px;
            max-height: 80vh;
            overflow: auto;
            @include scrollbar;
        }
    }
}

.detailTable {
    .ant-table-thead>tr>th {
        font-size: 12px;
    }

    .ant-table-tbody>tr>td {
        font-size: 12px;
    }

    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th {
        padding: 10px;
    }
}