$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.pathChartContent {

    .canvasLoadingBg{
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 88;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pathChartIconBox {
        position: absolute;
        top: -10px;
        right: 0px;
        width: 160px;
        height: 20px;
        font-size: 16px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        z-index: 101;

        .ant-checkbox-wrapper{
            margin-bottom: 0;
        }
        
        .anticon {
            cursor: pointer;
        }

        .anticon:hover {
            color: #0068B2;
        }
    }

    .pathChartSearchBox {
        position: absolute;
        top: -10px;
        left: 0px;
        width: 280px;
        height: 40px;
        display: flex;
        align-items: center;
        z-index: 101;
    }

    .sTypeColorBox {
        position: absolute;
        bottom: -10px;
        right: -30px;
        width: 400px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        transform: scale(0.8);
        z-index: 101;

        .sTypeItem {
            height: 24px;
            padding: 0 5px;
            line-height: 24px;
            font-weight: 400;
            color: #FFFFFF;
            font-family: Arial-Regular, Arial;
            user-select: none;
        }
    }

    .btnListBox {
        position: absolute;
        bottom: 1px;
        left: 0px;
        height: 2px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 101;

        .btnItem {
            width: 24px;
            height: 2px;
            border-radius: 1px 1px 1px 1px;
            margin-right: 8px;
            cursor: pointer;
        }

        .btnItem:last-of-type {
            margin-right: 0;
        }
    }

    .icon {
        font-size: 30px;
        color: #D8D8D8;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 30px);
    }
    .disableIcon{
        cursor: not-allowed;
        color: #D8D8D8 !important;
    }
    .icon:hover{
        color: #0068B2;
    }
    .rightIcon{
        right: 0;
        z-index: 101;
    }
    .leftIcon{
        left: 0;
        z-index: 101;
    }
    #downloadCanvasBox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -9999
    }
}