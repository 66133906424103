$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.typesProportionContent {
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;

    .selectBox2 {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selectItem2 {
            @include flex-c-c;
            // width: 24px;
            height: 22px;
            padding: 0 6px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #999999;
            cursor: pointer;
            font-size: 12px;
            font-family: PingFang SC Regular-Regular, PingFang SC Regular;
            font-weight: 400;
            color: #999999;
            margin-right: 6px;

            .selectItemIcon {
                width: 100%;
                height: 100%;
                color: #999999;

                div {
                    @include flex-c-c;
                    width: 100%;
                    height: 100%;
                }

                svg {
                    display: block;
                    height: 80%;
                    width: auto;
                }
            }

        }

        .activeSelectItem2 {
            background: #006BB3;
            border: 1px solid #006BB3;
            color: #fff;

            .selectItemIcon {
                color: #fff;
            }
        }
    }

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;

        &>span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFang SC-粗体, PingFang SC;
            font-weight: bold;
            color: #313131;

            p {
                font-size: 12px;
                color: #999;
                font-weight: normal;
                margin-bottom: 0;
                margin-left: 10px;
            }
        }

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .yearSelect {
            position: absolute;
            bottom: 6px;
            right: 10px;
            z-index: 99;
        }
    }

    .typesProportionContentBox {
        width: 100%;
        height: calc(100% - 20px);
        display: flex;
        align-items: center;
        justify-content: center;

        .typesProportionItemBox {
            width: 100%;
            height: calc(100% - 30px);

            .echartTipBox {
                width: calc(100% - 40px);
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-bottom: 10px;
                position: absolute;
                top: 36px;

                div {
                    font-size: 10px;
                    font-family: PingFang SC Regular-Regular, PingFang SC Regular;
                    font-weight: 400;
                    color: #313131;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:nth-of-type(1) {
                        i {
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #61D4AD;
                            margin-right: 10px;
                        }
                    }

                    &:nth-of-type(2) {
                        margin-left: 20px;

                        i {
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #0068B2;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .typesProportionItemContent {
                &>div {
                    &:nth-of-type(1) {
                        display: flex;
                        align-items: center;
                        // justify-content: space-between;
                        flex-wrap: wrap;
                    }
                }
            }

            .typesProportionItem {
                margin-right: 6px;
                margin-bottom: 6px;
            }
        }

        .pieChartBoxContent {
            width: 100%;
            height: 100%;

            .pieChartTitleBox {
                width: 100%;
                height: 20px;
                color: #999;
                font-weight: normal;
                font-size: 12px;
                text-align: center;

                .ant-typography {
                    margin-bottom: 0;
                    color: #999;
                    font-weight: normal;
                    font-size: 12px;
                    text-align: center;
                }
            }

            .pieChartBox {
                width: 100%;
                // height: calc(100% - 20px);
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .typesProportionItemCanvasBox {

                    // width: auto;
                    // height: 100%;
                    // width: calc(height);
                    // background: red;
                    .pieChartTitleBox {
                        width: 100%;
                        height: 20px;
                        color: #999;
                        font-weight: normal;
                        font-size: 12px;
                        text-align: center;

                        .ant-typography {
                            margin-bottom: 0;
                            color: #999;
                            font-weight: normal;
                            font-size: 12px;
                            text-align: center;
                        }
                    }
                }

                .typesProportionItemCanvasBoxBg {
                    // height: 100%;
                    // width: calc(height);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        height: 80%;
                        width: auto;
                        cursor: pointer;
                    }
                }

                .typesProportionItemCanvasBoxBgActive {
                    display: flex;
                    // align-items: center;
                    justify-content: center;

                    img {
                        margin-top: 20px;
                        display: block;
                        height: calc(80% - 15px);
                        width: auto;
                        cursor: pointer;
                    }
                }
            }
        }

    }

    #typesProportionBox {
        height: calc(100% - 24px);
        width: 100%;

        ~div,
        canvas {
            width: 100% !important;
            height: 100% !important;
        }

        .emptyContent {
            width: 100%;
            height: 100%;
            @include flex-c-c;
        }
    }

}

.empPopover {
    padding-bottom: 0px;
    padding-right: 40px;
    width: 116px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        padding: 8px 10px;
    }
}

.pieChartTip {
    display: flex;
    align-items: center;
    justify-content: center;

    .leftBox {
        div {
            height: 24px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #535353;
            text-align: right;
        }
    }

    .rightBox {
        margin-left: 20px;

        div {
            height: 24px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: end;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #535353;
            text-align: right;
            font-weight: 500;
        }
    }
}