$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.competitivenessAnalysisContent {
    width: 100%;
    height: 100%;
    background: #fff;

    .dataInput {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        margin-bottom: 10px;
    }

    .lineDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #0068B2;

        i {
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #0068B2;
        }

        span {
            display: inline-block;
            flex: none;
            padding: 0 16px;
        }
    }

    .chartsBox {
        width: 100%;
        height: calc(100% - 34px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgTitleBox {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                width: 14px;
                height: 14px;
                margin-right: 3px;
            }
        }

        .chartsLeftBox {
            width: 450px;
            height: 100%;

            .chartBox:first-of-type {
                margin-top: 0;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
        }

        .chartsRightBox {
            width: calc(100% - 470px);
            height: 100%;
            margin-left: 20px;
        }

        .chartBox {
            width: 100%;
            height: 330px;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid rgba(0, 104, 178, 0.1);
            flex: none;
            margin-top: 20px;
            box-sizing: border-box;

            &:nth-of-type(1) {
                margin-top: 0;
            }

            .headerTitleBox {
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #313131;
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .anticon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }

            .tableContentBox {
                height: calc(100% - 54px);
                width: 100%;
                // padding: 0 16px 16px 16px;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .paginationBox {
                    margin-top: 13px;
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }

        .comOrPeopleContent {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .comOrPeopleLogo {
            height: 30px;
            width: 30px;
            flex: none;
            border-radius: 50%;
            background: #0068B2;
            @include flex-c-c;
            margin-right: 5px;
            flex: none;

            img {
                display: inline-block;
                width: 20px;
                height: 20px;
            }

        }

        .tagBox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 3px;

            .ant-tag {
                font-size: 9.5px;
                border-radius: 10px;
                zoom: 0.8;
                background: #EFEFEF;
                border-color: #EFEFEF;
                color: #313131;
                margin-right: 8px;
                margin-top: 8px;
            }
        }
    }

    .deleteIcon {
        color: #535353;
        cursor: pointer;

        &:hover {
            color: #0068B2;
        }

        div {
            @include flex-c-c;
        }
    }
}

.tableColumnsParagraphTooltip {
    max-width: 300px;

    .tableColumnsParagraphTip {
        max-height: 225px;
        // width: 438px;
        font-size: 12px;
        width: auto;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}