$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.followCardContent {
    &:last-of-type {
        .followCard {
            margin-bottom: 0px;
            border-bottom: none;
        }
    }

    &:first-of-type {
        .ant-card-body {
            padding-top: 0px !important;
        }

        .status {
            top: 0 !important;
        }
    }

    .followCard {
        min-height: 60px;
        // margin-bottom: 10px;
        // background: #FBFBFD;
        // border-radius: 5px;
        user-select: none;
        position: relative;
        border-bottom: 1px dashed #D8D8D8;

        .ant-card-body {
            padding: 15px 0px;
        }

        .status {
            position: absolute;
            height: 22px;
            min-width: 50px;
            padding: 0 3px;
            top: 15px;
            left: 0;
            text-align: center;
            border-radius: 10px;
            font-size: 12px;
            line-height: 22px;
            font-weight: normal;
            color: #FFFFFF;
        }

        .top {
            padding-left: 56px;
            @include flex;
            @include flex-a-c;
            font-size: 14px;

            .typeBox {
                cursor: pointer;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: 600;
                color: #0068B2;
                margin-right: 5px;
                flex: none;
            }

            .textContent {
                width: calc(100% - 120px);
                color: #313131;
            }

            .iconBox {
                width: auto;
                height: 22px;
                @include flex;
                @include flex-j-s-b;
                font-size: 16px;
                padding-top: 5px;
                flex: none;

                .anticon,
                .iconSvg {
                    margin-right: 2px;
                    margin-left: 2px;
                    cursor: pointer;
                    height: 16px;
                    width: 16px;

                    svg,div {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

        .bottom {
            @include flex;
            width: 100%;
            padding-left: 56px;
            font-size: 12px;
            margin-top: 3px;

            // .valueItem:last-of-type {
            //     flex: 1.3;
            // }

            .valueItem {
                width: 50%;
                flex: 1;
                margin-right: 5px;
                @include flex;
                align-items: center;

                // font-weight: 500;
                .label {
                    margin-right: 5px;
                    font-family: PingFang SC-常规, PingFang SC;
                    font-weight: normal;
                    color: #9E9E9E;
                    width: 38px;
                    flex: none;
                }

                .value {
                    width: calc(100% - 43px);
                    color: #535353;
                    font-family: PingFang SC-中等, PingFang SC;
                    font-weight: normal;
                }

                .ant-typography {
                    color: #535353;
                    font-family: PingFang SC-中等, PingFang SC;
                    font-weight: normal;
                }
            }
        }
    }

    .ant-typography p,
    div.ant-typography {
        margin-bottom: 0;
    }
}