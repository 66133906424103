$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondCreateReportBox {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .createReportContent {
        width: 100%;
        height: calc(100% - 25px);
        padding-top: 24px;
        padding-left: 15px;

        .createReportList {
            width: 100%;
            height: calc(100% - 40px);

            .settingTitle {
                font-size: 14px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #000000;
                margin-bottom: 24px;
            }

            .settingChildrenItem {
                display: flex;
                // align-items: center;
                margin-bottom: 14px;

                .checkBox {


                    .ant-checkbox-wrapper {
                        padding-left: 25px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #535353;
                        margin: 5px 0;

                    }

                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: #61D4AD !important;
                        border-color: #61D4AD !important;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner:after {
                        border: 1px solid #ffffff !important;
                        border-top: 0 !important;
                        border-left: 0 !important;
                    }
                }

                .typeCheckBox {
                    flex: none;
                    display: flex;
                    align-items: center;
                    width: 260px;
                    height: 45px;

                    .typeCheckContent {
                        @include flex-c-c;
                        font-size: 14px;
                        font-family: PingFang SC-Bold, PingFang SC;
                        font-weight: bold;
                        color: #313131;
                    }
                }
            }

            .settingOptionBoxContent {
                display: flex;
                // flex-wrap: wrap;
            }

            .settingOptionBox {
                display: flex;
                align-items: center;
                // padding-left: 25px;
                padding: 5px 0 5px 14px;
                // width: 290px;

                .label {
                    font-size: 12px;
                    font-weight: normal;
                    color: #535353;
                    margin-right: 10px;
                    min-width: 50px;
                    // flex: none;
                    display: inline-block;
                    text-align: justify;
                    text-align-last: justify
                }

                .optionBox {
                    .myInput {

                        &:focus,
                        &:hover {
                            border-color: #016DB4;
                        }
                    }
                }
            }

        }

        .createReportBtnBox {
            height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-btn {
                width: 188px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sharesTip {
        padding-bottom: 5px;
    }
}

.reportSubjectScopeSearchPoP {
    .ant-select-item {
        width: 100%;
    }

    .ant-select-item-option-content {
        width: 100%;
        // display: flex;
        // align-items: center;
        color: rgba(0, 0, 0, .85);
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}