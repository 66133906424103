$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.createContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;
    @include flex-c-c;

    .createBox {
        width: 960px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .createStepBox {
            height: 50px;
            width: 100%;
            @include flex-c-c;
            margin-top: 4px;

            .createStepItem:first-of-type {
                margin-left: 0;
            }

            .createStepItem {
                width: 200px;
                font-size: 22px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #9E9E9E;
                @include flex-c-c;
                flex-wrap: wrap;
                margin-left: 40px;

                .line {
                    flex: none;
                    width: 100%;
                    height: 3px;
                    background: #D8D8D8;
                    border-radius: 10px 10px 10px 10px;
                }
            }

            .activeCreateStepItem {
                color: #0068B2;

                .line {
                    background: #0068B2;
                }
            }
        }

        .createContentBox {
            width: 100%;
            height: calc(100% - 120px);
            margin-top: 12px;
            display: flex;
            justify-content: center;

            .noData {
                height: 100%;
                height: 100%;
                @include flex-c-c;
                flex-direction: column;

                .ant-empty-image {
                    height: 160px;
                }

                .createBtnBox {
                    height: 80px;
                    width: 600px;
                    margin-top: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

                    .createBtnItem {
                        width: 80px;
                        height: 80px;
                        background: #66A4D1;
                        box-shadow: 0px 4px 10px 0px rgba(0, 104, 178, 0.3);
                        border-radius: 50%;
                        font-size: 12px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        @include flex-c-c;
                        cursor: pointer;
                        flex-direction: column;

                        .createBtnItemIcon {
                            margin-bottom: 5px;

                            div {
                                height: 32px;
                                width: 32px;
                            }

                            svg {
                                height: 32px;
                                width: 32px;

                                path {
                                    fill: #FFFFFF;
                                }
                            }
                        }
                    }
                }
            }

            .tableContentBox {
                // max-height: 400px;
                width: 100%;

                .selectItem {
                    td {
                        background: #f2f6fa !important;
                    }
                }

                .iconBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;
                    user-select: none;
                    flex-direction: row-reverse;
                    margin-bottom: 12px;
                    color: #313131;

                    .clearAll {

                        div,
                        svg {
                            height: 20px;
                            width: 20px;
                        }

                        div {
                            @include flex-c-c;
                        }

                        path {
                            fill: #313131;
                        }
                    }

                    .clearAll:hover {
                        path {
                            fill: #0068B2;
                        }
                    }

                    .anticon:hover {
                        color: #0068B2;
                    }

                    .anticon {
                        margin-right: 16px
                    }
                }

            }

            .ruleContentBox {
                width: 600px;
                margin-top: 12px;

                .itemContentBox {
                    display: flex;
                    margin: 20px 0;
                    flex-wrap: wrap
                }

                .checkBox {
                    @include flex-c-c;
                    height: 40px;
                    background: #EFEFEF;
                    border-radius: 4px 4px 4px 4px;
                    padding: 0 10px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    margin: 0px 10px 20px 14px;
                    cursor: pointer;
                    position: relative;
                    
                    .iconCircle{
                        display: block;
                        width: 14px;
                        height: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: -7px;
                        right: -7px;
                        background: #bbbbbb;
                        font-size: 8px;
                        border-radius: 50%;
                        
                        .anticon {
                            color: #fff;
                        }
                    }
                    
                }

                .checkedBox {
                    color: #FFFFFF;
                    background: #0068B2;

                    .iconCircle {
                        background: #61D4AD !important;
                    }
                }

                .checkboxItem {
                    width: 50%;
                    padding-left: 14px;

                    .ant-checkbox-disabled+span {
                        color: #0068B2 !important;
                    }
                }

                .numberInputBox {
                    // margin-top: 10px;
                    width: 100%;
                    padding-left: 14px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .numberInput {
                        width: calc(100% - 135px);
                        margin: 0 8px;
                    }

                    .inputErrorMsgBox {
                        position: absolute;
                        top: 34px;
                        left: 120px;
                        font-size: 12px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FE545F;
                    }
                }

                .recoveryBtnBox {
                    margin-top: 24px;

                    .recoveryBtn {
                        width: 80px;
                        height: 28px;
                        @include flex-c-c;
                        font-size: 12px;

                        .anticon {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        .createContentBtnBox {
            width: 100%;
            height: 40px;
            margin-top: 10px;
            @include flex-c-c;

            .ant-btn {
                width: 188px;
                height: 40px;
            }
        }
    }
}