$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.messageModal {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 15px;
            border-radius: 10px 10px 0 0;

            .ant-modal-title {
                font-size: 16px;
                font-weight: 400;
                color: #313131;
            }
        }

        .ant-modal-close-x {
            width: 43px;
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-body {
            padding: 20px 0;

        }
    }

    .messageModalContent {
        @include flex-c-c;
        flex-direction: column;
        max-height: 70vh;
        overflow-y: auto;
        @include scrollbar;
        padding: 0 20px;

        .iconBox {
            font-size: 34px;
            color: red;
        }

        .descriptionBox {
            font-size: 16px;
            font-weight: 400;
            color: #535353;
            text-align: center;
            word-break: break-all;
        }

        .messageBox {
            font-size: 14px;
            font-weight: normal;
            color: #9E9E9E;
            margin: 10px 0;
            text-align: center;
        }

        .infoTextBox {
            width: 100%;
            font-size: 14px;
            font-weight: normal;
            color: #9E9E9E;
            margin: 10px 0;
            text-align: left
        }

        .btnBox {
            @include flex-c-c;
            flex-direction: row;
            margin: 20px 0 10px 0;
            width: 100%;

            .ant-btn {
                margin-left: 0;

                &:first-of-type {
                    margin-right: 20px;
                }

                &:hover {
                    border-color: #0068B2;
                }
            }
        }
    }
}