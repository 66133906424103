$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.updateLogsDrawer {
    border-radius: 10px 0px 0px 10px;

    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px;

        p {
            word-break: break-all;
        }
    }

    .msg {
        font-size: 16px;
        font-weight: bold;
    }

    .noDataDivider {
        margin: 0;
    }

    .emptyBox {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-empty-description {
            color: #9E9E9E;
        }
    }

    .loadingBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .noticeTabs {
        .ant-tabs-nav {
            width: calc(100% - 40px);
            margin-left: 20px;
        }
    }

    .drawerFooterBtnBox {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .ant-btn {
            min-width: 90px;
            margin-left: 10px;
            // color: #fff;
            // background: #016DB4;
            // border-color: #0068B2;
        }

        .noBg {
            // color: #535353;
            // background: #FFFFFF;
            // border-color: #D9D9D9;
        }
    }
}


.mySpinLoading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .myLoadingIcon {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }

    .ant-spin-text {
        color: #9E9E9E;
        font-size: 15px;
    }
}