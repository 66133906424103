$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.projectManagementContent {
    // padding: 10px;
    width: 100%;
    height: 100%;

    .topBox {
        width: 100%;
        height: 150px;
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .searchBox {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .searchItem {
                display: flex;
                align-items: center;
                height: 35px;

                .label {
                    margin-right: 20px;
                    font-weight: normal;
                    color: #313131;
                }

                .optionsBox {
                    display: flex;

                    .optionItem {
                        display: inline-block;
                        cursor: pointer;
                        margin-right: 30px;
                        color: #9E9E9E;
                    }

                    .borderBottom {
                        width: 200px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                        padding: 0px;
                    }
                }
            }
        }
    }

    .filterItem {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        user-select: none;
        cursor: pointer;

        .optionItem {
            display: inline-block;
            margin-right: 10px;
            color: #535353;
            font-weight: normal;
        }
    }

    .cardContent {
        width: 100%;
        height: calc(100% - 190px);
        overflow-y: auto;
        background: #fff;
        border-radius: 10px;
        // display: flex;

        .noData {
            width: 100%;
            height: 100%;
            @include flex-c-c;
        }

        .cardBox {
            width: 100%;
            // height: 100%;
            @include flex;
            @include flex-j-s-b;
            align-items: flex-start;
            // @include flex-a-s-b;
            flex-wrap: wrap;
            // background: rebeccapurple;
            padding: 0 $mainPadding;
            // margin: $mainPadding 0;
        }

        .paginationBox {
            margin-top: 20px;
            display: flex;
            flex-direction: row-reverse;
            padding: 0 $mainPadding;
        }
    }

    .cardBtn {
        height: 28px;
        background: #EFEFEF;
        border-radius: 4px 4px 4px 4px;
        border: none;
        font-size: 12px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #535353;
        @include flex-c-c;
        &:hover {
            background: #0068B2;
            color: white !important;
            .anticon,.cardBtnSvg{
                color: white !important;
            }
        }
        .anticon+span{
            margin-left: 4px;
        }
        .anticon{
            @include flex-c-c;
            color: #9E9E9E;
        }
        .cardBtnSvg {
            margin-right: 4px;
            display: inline-block;
            color: #9E9E9E;
            div {
                @include flex-c-c;
            }
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}