$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.PublicSentimentBox {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .infoBox {
        padding-left: 10px;
        width: 100%;
        height: 100%;

        .tableBox {
            height: 100%;
            width: 100%;
            padding-top: 10px;

            .dateBox {
                font-size: 14px;
                font-family: PingFang SC-中等, PingFang SC;
                font-weight: normal;
                color: #9E9E9E;
                // margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dataInput {
                    @include flex-c-c;

                    .inputBox {
                        @include flex-c-c;
                        margin-left: 20px;
                        width: 460px;

                        .label {
                            font-size: 14px;
                            color: #313131;
                            width: 60px;
                            flex: none;
                        }

                        .ant-select {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                            box-sizing: border-box;
                        }
                    }

                    .sumAmountBox {
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #313131;
                        margin: 0 10px 0 20px;
                        display: flex;
                        align-items: baseline;
                        justify-content: center;

                        p {
                            display: block;
                            height: 32px;
                            font-size: 28px;
                            font-family: Century Gothic-Bold, Century Gothic;
                            font-weight: bold;
                            color: #FE545F;
                            margin: 0 5px;
                            line-height: 25px;
                        }

                        span {
                            font-size: 14px;
                            font-family: PingFang SC-中等, PingFang SC;
                            font-weight: normal;
                            color: #FE545F;
                        }
                    }
                }

                .iconBox {
                    color: #313131;
                    font-size: 16px;

                    .anticon:hover {
                        // text-decoration: underline
                        color: #0068B2;
                    }
                }
            }

            .tableContent {
                height: calc(100% - 45px);
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cardListBox {
                    width: 100%;
                    height: 100%;
                }
            }


        }

        .publicSentimentCard {
            position: relative;
            width: 100%;
            height: auto;
            min-height: 210px;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #EFEFEF;
            margin-bottom: 24px;
            padding: 24px;
            display: flex;

            .ant-image {
                flex: none;
                margin-right: 16px;
                @include flex-c-c;

                .ant-image-img {
                    width: auto;
                    max-width: 100%;
                }
            }

            .hoverPic {
                user-select: none;
                color: #0068B2;
                border-radius: 4px;
                background-color: #F4F8FB;

                // &:hover {
                //     background: rgba($color: #0068B2, $alpha: 0.1);
                //     color: #0068B2;
                // }

            }

            .publicSentimentImg {
                height: 160px !important;
                line-height: 160px;
                margin-right: 16px;
                flex: none;

                .ant-avatar-string {
                    line-height: 160px !important;
                }
            }

            .publicSentimentCardContent {
                width: calc(100% - 270px);

                .cardTitle {
                    font-size: 16px;
                    font-family: PingFang SC-粗体, PingFang SC;
                    font-weight: 600;
                    color: #313131;
                }

                .contentBox_ {
                    width: calc(100% - 140px);

                    .labelBox {
                        margin: 8px 0 16px 0;
                        font-size: 12px;
                        font-family: PingFang SC-中等, PingFang SC;
                        font-weight: normal;
                        color: #535353;

                        span {
                            font-size: 12px;
                            font-family: PingFang SC-常规, PingFang SC;
                            font-weight: normal;
                            color: #9E9E9E;
                            margin-right: 5px;
                        }

                        .warnImg{
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            position: relative;
                            top: -1px;
                            margin-left: 2px;
                            cursor: pointer;
                        }
                    }

                    .cardText {
                        font-size: 12px;
                        height: 92px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9E9E9E;
                        max-height: 100px;
                        overflow: hidden;
                        margin-bottom: 0;
                    }
                }

                .btnBox {
                    width: 100px;
                    margin-left: 40px;
                    margin-right: 20px;
                    flex: none;
                    @include flex-c-c;
                    flex-direction: column;

                    .timeBox {
                        font-size: 24px;
                        font-family: Century Gothic-Regular, Century Gothic;
                        font-weight: 400;
                        color: #313131;
                    }

                    .yearBox {
                        font-size: 12px;
                        font-family: PingFang SC-中等, PingFang SC;
                        font-weight: normal;
                        color: #9E9E9E;
                        margin: 8px 0;
                    }

                    .ant-btn {
                        height: 32px;
                        width: 96px;
                        border-radius: 21px 21px 21px 21px;
                        @include flex-c-c;

                        .anticon {
                            font-size: 18px;
                            margin: 0;
                            transform: rotate(180deg);
                            margin-left: 5px;
                        }
                    }
                }
            }

            .iconBox {
                position: absolute;
                top: 10px;
                right: 10px;
                width: auto;
                height: 20px;
                font-size: 16px;
                @include flex-c-c;

                .iconSvg {
                    margin-left: 5px;
                    margin-right: 0;
                    height: 16px;
                    width: 16px;

                    svg,
                    div {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

        .publicSentimentCard:last-of-type {
            margin-bottom: 0;
        }
    }
}

.publicSentimentBoxModal {
    .ant-modal {
        width: 70vw !important;

    }

    .ant-modal-content {
        border-radius: 10px;
    }

    .ant-modal-header {
        border-radius: 10px 10px 0 0;
    }

    .ant-modal-body {
        padding: 20px 0;
    }

    .showContentBox {
        // font-size: 12px;
        font-family: PingFang SC-常规, PingFang SC;
        font-weight: normal;
        color: #535353;
        // white-space: pre-line;
        max-height: 70vh;
        overflow-y: auto;
        padding: 0 20px;

        .imgBox {
            display: flex;
            flex-wrap: wrap;

            .ant-image {
                // margin: 10px;
            }
        }

        img {
            max-width: 100%;
        }

        .showContentImg {
            margin: 10px;
        }

        p {
            // margin-bottom: 0;
        }
    }
}