$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.overviewContent {
    width: 100%;
    height: 100%;
    background: #fff;

    .dataInput {
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        // @include flex-c-c;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        position: relative;

        .inputBox {
            @include flex-c-c;
            margin-left: 24px;
            width: 300px;

            .label {
                font-size: 14px;
                color: #313131;
                width: 60px;
                flex: none;
            }

            .ant-select {
                border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                box-sizing: border-box;
                color: #9E9E9E
            }
        }

        .infoIcon {
            font-size: 18px;
            color: #0068B2;
            position: absolute;
            right: 0;
            top: 6px;
            cursor: help;
        }
    }

    .chartsContentBox {
        height: calc(100% - 42px);
        width: 100%;

        .chartsContent {
            width: 100%;
            height: 100%;
            // background: #9E9E9E;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            div:first-of-type {
                margin-top: 0;
            }

            .wholeRowBox {
                width: 100%;
                min-height: 310px;
                border-radius: 10px;
                border: 1px solid rgba(0, 104, 178, 0.1);
                margin-top: 24px;
            }

            .halfRowBox {
                width: calc(50% - 12px);
                min-height: 310px;
                border-radius: 10px;
                border: 1px solid rgba(0, 104, 178, 0.1);
                margin-top: 24px;
            }
        }
    }
}

.collenctionTooltip {
    max-width: 450px;

    .collenctionTip {
        // max-height: 225px;
        width: 426px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        color: #fff;
        @include tooltipScrollbar;
        white-space: pre-wrap;

        h3 {
            color: #fff;
            margin-bottom: 0px;
        }

        span {
            display: block;
        }
    }
}