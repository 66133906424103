$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.copyModal {
    .ant-modal {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 20px;
        }

        .ant-modal-close-x {
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 20px;
            height: 70vh;
        }

    }

    .copyModalContent {
        width: 100%;
        height: 100%;
        @include flex-c-c;

        .copyModalContentBox {
            height: 100%;
            width: 100%;

            .copyModalContentTableBox {
                height: calc(100% - 40px);
                width: 100%;
                padding-bottom: 10px;
                display: flex;

                .copyModalContentLeftTable {
                    width: 200px;
                    height: 100%;
                    border-right: 1px solid #EFEFEF;

                    .copyModalContentLeftTableItem:last-of-type {
                        margin-bottom: 0;
                    }

                    .activeItem {
                        border: 1px solid #0068B2 !important;
                        position: relative;

                        .checkBox {
                            display: flex !important;
                            @include flex-c-c;
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            width: 18px;
                            height: 15px;
                            background: #0068B2;
                            color: #FFFFFF;
                            font-size: 12px;
                            border-radius: 0px 4px 0px 4px;
                        }
                    }

                    .copyModalContentLeftTableItem {
                        width: 176px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #EFEFEF;
                        margin-bottom: 16px;
                        padding: 12px;
                        cursor: pointer;

                        .dateItem {
                            font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #535353;
                        }

                        .textItem {
                            font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #535353;

                            span {
                                color: #0068B2;
                                margin-left: 5px;
                            }
                        }

                        .checkBox {
                            display: none;
                        }
                    }
                }

                .copyModalContentRightTable {
                    width: calc(100% - 200px);
                    height: 100%;
                    padding-left: 24px;
                }
            }

            .copyModalContentBtnBox {
                height: 40px;
                width: 100%;
                @include flex-c-c;

                .ant-btn {
                    width: 90px;
                }
            }
        }
    }
}