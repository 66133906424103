$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.companyInfoDrawer {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px;
        @include scrollbar;
    }

    .companyInfoContent {
        padding: $mainPadding;
        width: 100%;
        height: 100%;

        .companyInfoTitle {
            font-size: 16px;
            font-family: PingFang SC-粗体, PingFang SC;
            font-weight: 500;
            color: #0068B2;
        }

        .companyInfoBox {
            height: 100%;
            width: 100%;

            .typeBox {
                display: flex;

                .typeItem {
                    // width: 56px;
                    height: 22px;
                    font-size: 14px;
                    font-family: PingFang SC-中等, PingFang SC;
                    font-weight: normal;
                    color: #9E9E9E;
                    margin-left: 10px;
                    font-weight: 500;
                    cursor: pointer;

                    // line-height: 22px;
                    &:hover {
                        color: #0068B2;
                        border-bottom: 1px solid #0068B2;
                    }
                }

                .activeType {
                    color: #0068B2;
                    border-bottom: 1px solid #0068B2;
                }
            }

            .dateBox {
                font-size: 14px;
                font-family: PingFang SC-中等, PingFang SC;
                font-weight: normal;
                color: #9E9E9E;
                margin: 5px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .downloadIcon {
                    font-size: 16px;
                    color: #535353;
                    cursor: pointer;
                }
            }

            .businessContent {

                // margin-top: 10px;
                .myDescriptions {
                    .ant-descriptions-item-label {
                        width: 130px;
                        padding: 10px 15px;
                    }

                    .ant-descriptions-item-content {
                        padding: 10px 15px;
                        position: relative;
                    }

                    .warnImgBox {
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: help;

                        img {
                            display: block;
                            width: 12px;
                            height: 12px
                        }
                    }
                }
            }

            .shareholderContent {
                margin-top: 10px;
            }

            .personnelContent {
                margin-top: 10px;
            }
        }

        .tagBox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 3px;
            text-align: left;

            .ant-tag {
                font-size: 9.5px;
                border-radius: 10px;
                zoom: 0.8;
                background: #EFEFEF;
                border-color: #EFEFEF;
                color: #313131;
                margin-right: 8px;
                margin-top: 8px;
            }
        }
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
        padding: 10px;
    }
}

.tableColumnsParagraphTooltip {
    max-width: 450px;

    .tableColumnsParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}