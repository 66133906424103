.MyRangePickerContent {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: border .3s, box-shadow .3s;
}

.MyRangePickerContent .startDatePicker,
.MyRangePickerContent .endDatePicker {
    width: calc(50% - 29px);
}

.MyRangePickerContent .spaceCharacter,
.MyRangePickerContent .suffixIcon {
    position: relative;
    display: inline-flex;
    width: 30px;
    padding: 0 10px;
    height: 16px;
    color: rgba(0, 0, 0, .25);
    font-size: 16px;
    vertical-align: top;
    cursor: default;
    justify-content: center;
    align-items: center;
}

.MyRangePickerContent .suffixIcon {
    font-size: 14px;
    padding: 0 10px 0 0;
}

.MyRangePickerContent .suffixIcon .closeIcon {
    cursor: pointer;
    color: rgba(0, 0, 0, .25) !important;
}

.MyRangePickerContent .endDatePicker,
.MyRangePickerContent .startDatePicker {
    padding-right: 0;
    padding-left: 0;
}

.MyRangePickerContent .activePicker {
    border-bottom: 1px solid #0068B2 !important;
}

.MyRangePickerContent .startDatePicker {
    margin-left: 11px;
}

.MyRangePickerContent:hover,
.MyRangePickerContent:focus {
    border-color: #0068B2 !important;
}

.MyRangePickerContentFocus {
    box-shadow: 0 0 0 2px rgba(0, 104, 178, 0.2);
}

.disMyRangePickerContent:hover,
.disMyRangePickerContent:focus {
    border-color: #d9d9d9 !important;
}

.disMyRangePickerContent {
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;

}

.disMyRangePickerContent .closeIcon,
.disMyRangePickerContent .suffixIcon,
.disMyRangePickerContent .spaceCharacter {
    cursor: not-allowed !important;
}
.MyRangePickerContent .ant-picker{
    padding: 3px 0px;
}