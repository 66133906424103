$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.iconBarContent {
    height: calc(100% - 30px);
    width: 100%;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconBarContentBox {
        // height: 100%;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .iconBarItemBox {
            height: 24px;
            width: 100%;
            margin-top: 4px;
            display: flex;
            align-items: center;

            .iconBarItemIcon {
                height: 22px;
                width: 22px;

                svg {
                    // width: 22px;
                    // height: 22px;
                    fill: var(--icon-color);

                    path {
                        fill: var(--icon-color);
                    }

                    ellipse {
                        stroke: var(--icon-color);
                    }

                }
            }
        }
    }
}

.iconBarPopover {
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 2px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        max-width: 800px;
        padding: 8px 5px 8px 8px;
    }

    .iconBarPopoverBox {
        .title {
            font-weight: bold;
        }

        .textBox {
            display: flex;
            align-items: center;

            .circleI {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 4px;

            }

            .textLeft {
                font-weight: bold;
            }

            .textRight {
                margin-left: 20px;
                float: right;
            }
        }
    }
}