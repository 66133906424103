$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  min-width: 1500px;
  min-height: 750px;
  overflow: auto;
  // overflow-x: auto;
}

/* Input输入框type=number时去掉内部上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ant-input-textarea-show-count:after {
  font-size: 12px;
  // transform: translate(-8px, -20px);
}

textarea {
  @include scrollbar;
  padding: 4px 6px 4px 11px;
  height: calc(100% - 20px) !important;
}

// .ant-picker-dropdown {
//   overflow: hidden;
//   width: 280px!important;
//   .ant-picker-header-next-btn,.ant-picker-header-super-next-btn{
//     visibility:unset !important
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-drawer-extra {
  &:hover {
    color: #0068B2;
  }
}

.hoverIcon {
  &:hover {
    color: #0068B2 !important;
  }
}

.iconSvg {
  height: 14px;
  width: 14px;
  margin-right: 5px;
  cursor: pointer;
  color: #313131;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  div {
    height: 14px;
    width: 14px;
    @include flex-c-c;
  }

  svg {
    height: 14px;
    width: 14px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

#nprogress .spinner {
  display: none !important;
}

#root {
  .ant-btn-primary {
    border-color: #0068B2;
    background: #0068B2;
  }

  .ant-btn-primary[disabled] {
    color: #c1c1c1;
  }

  .ant-btn-primary:hover {
    border-color: #0068b2df;
    background: #0068b2df;
  }
}

.myTabs {
  height: 100%;
  width: 100%;

  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-nav {
    margin: 0 0 15px 0;
  }

  .ant-tabs-tab:first-of-type {
    margin-left: 13px;
  }

  .ant-tabs-tab {
    padding: 10px 0px 5px 0;
    margin-left: 50px;
  }

  .ant-tabs-nav-list {
    // padding-left: 20px;
  }

  .ant-tabs-tab-btn {
    color: #9E9E9E;
  }

  .ant-tabs-content-holder {
    height: calc(100% - 30px);
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #006AB2
    }
  }

  .ant-tabs-ink-bar {
    // width: 20px !important;
    background: #006AB2;
  }
}

.myPagination {
  .ant-pagination-item {
    a:hover {
      color: #0068B2;
      // background: rgba(0, 104, 178, 0.09803921729326248);
    }
  }

  .ant-pagination-item-active {
    a {
      color: #0068B2;
      background: rgba(0, 104, 178, 0.09803921729326248);
    }
  }
}

.drawerFooterBtnBox {
  @include flex;
  @include flex-a-c;
  flex-direction: row-reverse;

  .ant-btn {
    min-width: 90px;
    margin-left: 10px;
    // color: #fff;
    // background: #016DB4;
    // border-color: #0068B2;
  }

  .noBg {
    // color: #535353;
    // background: #FFFFFF;
    // border-color: #D9D9D9;
  }
}

.myTable {
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    width: 0;
    height: 0;
  }

  .ant-table-body {
    @include scrollbar;
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ant-table-thead {

      .ant-table-cell {
        &:first-of-type {
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
        }

        background: #EFEFEF;
        font-size: 14px;
        font-weight: 500;
        color: #535353;
        padding: 8px;
      }

      .ant-table-cell-scrollbar:not([rowspan]) {
        box-shadow: none
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          padding: 8px;
          font-size: 14px;
          font-weight: normal;
          color: #535353;
        }
      }
    }
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-header {
    border-radius: 10px 10px 0 0
  }
}

.myFormTable2 {
  .ant-spin {
    max-height: none !important;
  }

  .myLoadingIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  .ant-table-body {
    @include scrollbar;
  }

  .ant-table-cell:hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-table-cell-row-hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-thead>tr>th {
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #535353;
    background: rgb(242, 246, 250);
    border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table-cell {
    color: #535353;
  }
}

.myFormTable {

  .ant-spin {
    max-height: none !important;
  }

  .myLoadingIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  // .ant-table-body {
  //   @include scrollbar;
  // }

  // .ant-table-body{
  //   border-bottom-left-radius: 10px;
  //   border-bottom-right-radius: 10px;
  //   border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
  // }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
    border-right: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
    border-top: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table-container {
    border-radius: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  .ant-table table {
    border-radius: 10px 10px 0 0;
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  .ant-table-tbody tr:last-of-type td {
    border-bottom: none;
  }

  .ant-table-tbody tr:last-of-type td:first-child {
    border-bottom-left-radius: 10px;
  }

  .ant-table-tbody tr:last-of-type td:last-child {
    border-bottom-right-radius: 10px;
  }


  .ant-table-thead>tr>th {
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #535353;
    background: rgb(242, 246, 250);
    border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table-tbody>tr>td {
    font-size: 14px;
    font-family: PingFang SC-中等, PingFang SC;
    font-weight: normal;
    color: #535353;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
  }

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 16px;
  }

  .noPadding {
    padding: 0px !important;
  }

  .ant-table-cell:hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-table-cell-row-hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-typography p,
  div.ant-typography {
    margin-bottom: 0;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-header {
    border-right: 1px solid rgba(0, 104, 178, 0.1);
    border-radius: 10px 10px 0 0
  }

}

.noBorderTable {

  .ant-spin {
    max-height: none !important;
  }

  .myLoadingIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  .ant-table-body {
    @include scrollbar;
  }

  // .ant-table-body{
  //   border-bottom-left-radius: 10px;
  //   border-bottom-right-radius: 10px;
  //   border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
  // }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
    border-right: none;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
    border-top: none;
  }

  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: none;
  }

  .ant-table-container {
    border-radius: 4px;
    box-sizing: border-box;
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }

  .ant-table table {
    border-radius: 4px 4px 0 0;
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }

  .ant-table-tbody tr:last-of-type td {
    border-bottom: none;
  }

  .ant-table-tbody tr:last-of-type td:first-child {
    border-bottom-left-radius: 4px;
  }

  .ant-table-tbody tr:last-of-type td:last-child {
    border-bottom-right-radius: 4px;
  }


  .ant-table-thead>tr>th {
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #787878;
    background: #efefef;
    border-bottom: none;
  }

  .ant-table-tbody>tr>td {
    font-size: 12px;
    font-family: PingFang SC-中等, PingFang SC;
    font-weight: normal;
    color: #787878;
    border-bottom: 1px solid #EFEFEF;
  }


  .ant-table-thead>tr>th {
    padding: 4px;
    text-align: center;
  }

  .ant-table-tbody>tr>td {
    padding: 8px 4px;
    text-align: center;
  }

  .ant-table-thead>tr>th::before {
    height: 0 !important;
    width: 0 !important;
  }

  .noPadding {
    padding: 0px !important;
  }

  .ant-table-cell:hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-table-cell-row-hover {
    background: rgb(242, 246, 250) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-typography p,
  div.ant-typography {
    margin-bottom: 0;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-header {
    border-right: none;
    border-radius: 4px 4px 0 0
  }

}

.myDescriptions {

  .ant-descriptions-view,
  .ant-descriptions-item-content,
  .ant-descriptions-item-label,
  .ant-descriptions-row {
    border-color: rgba(0, 104, 178, 0.1000);
  }

  .ant-descriptions-view {
    border-radius: 4px;
  }

  .ant-descriptions-row {
    display: flex;
  }

  .ant-descriptions-item-content {
    color: #535353;
    // flex: 1;
    display: flex;
    align-items: center;
  }

  .ant-descriptions-item-label {
    background: rgb(242, 246, 250);
    text-align: center;
    color: #535353;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.myCardPopover {
  padding-top: 5px;
  padding-left: 5px;
  width: 300px;
  max-height: 400px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 4px;
    box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: #0068B2 !important;

    &:after {
      border: 1px solid #0068B2;
      border-top: 0;
      border-left: 0;
    }
  }

  &:after {
    border-color: #0068B2;
    color: #0068B2;
  }

  &+span {
    color: #0068B2;
    font-weight: 500;
  }
}

.ant-checkbox-disabled {

  &+span {
    color: rgba(0, 0, 0, .25) !important;
    font-weight: 500;
  }

  .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
}

.ant-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #0068B2;
  }
}

.ant-checkbox-wrapper {
  margin-bottom: 5px;

  &:hover {
    .ant-checkbox-inner {
      border-color: #0068B2;
    }
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    // border-color:#0068B2;
  }

  .ant-checkbox-inner:after {
    height: 2px;
    background-color: #0068B2;
  }
}

.ant-btn {
  border-radius: 4px;
}


.myModal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 20px;
    }
  }

  .messageModalContent {
    @include flex-c-c;
    flex-direction: column;

  }
}

.myRadio {
  .ant-radio-checked:after {
    border: 1px solid #0068B2;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #0068B2;
  }

  .ant-radio-inner:after {
    background-color: #0068B2;
  }

  .ant-radio.ant-radio-disabled .ant-radio-inner {
    border-color: #d9d9d9 !important;
  }

  .ant-radio-disabled .ant-radio-inner:after {
    background-color: rgba(0, 0, 0, .2) !important;
  }
}

.myRadioGroup {
  background: #0068B2;
  border-radius: 34px 34px 34px 34px;
  padding: 2px;

  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }

  .ant-radio-button-wrapper {
    background: #0068B2;
    border-radius: 34px 34px 34px 34px;
    border: 1px solid #0068B2;
    color: #fff;
    height: 30px;
    line-height: 30px;
    min-width: 80px;
    text-align: center;
  }

  .ant-radio-button-wrapper-checked {
    border: 1px solid #fff !important;
    background: #fff !important;
    color: #0068B2 !important;
    box-shadow: none !important;
  }
}

.ant-tooltip-inner {
  border-radius: 4px;
}

.notFoundPage {
  background: #fff;
  width: 100%;
  height: 100%;
  @include flex-c-c;

  .wscn-http404-container {
    transform: unset !important;
    position: unset !important;
  }
}

.companyTypeTag {
  margin: 0px;
  margin-right: 8px;
  color: #0068B2;
  border-color: #0068B2;
  background: #ffffff;
  height: 18px;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0068B2;
  line-height: 16px;
  border-radius: 20px;
  padding: 0 8px;
  box-sizing: border-box;
}

.userOptionsItemBoxSpan {
  font-size: 12px;
  color: #535353;
  margin-left: 8px;
  line-height: 26px;
}

.userOptionsItemBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-tooltip {
  padding-bottom: 5px;
  padding-top: 5px;
}

.activeScrollBars {
  >div {
    &:nth-of-type(2) {
      display: none !important;

      div:active {
        background: rgba(0, 0, 0, 0.5) !important;
      }

      div:focus {
        background: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}

.quarterPickerDropdown {

  // .ant-picker-year-panel,
  // .ant-picker-quarter-panel {
  //   width: 230px;
  // }

  .ant-picker-cell:hover {
    &::before {
      background: rgba(0, 0, 0, .04);
      ;
    }
  }

  // 修改选中的年份、季度背景颜色
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: #0068B2;
    }
  }

  // 使用CSS改变季度选择器的内容
  .ant-picker-quarter-panel {
    .ant-picker-content {

      // 使季度选择器可以换行成两排
      tr {
        display: flex;
        flex-wrap: wrap;
      }

      // 控制按钮外层宽度，使器溢出换行
      td {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50px;
        padding: 20px;
      }
    }

    // 使before伪元素和按钮背景颜色一致
    .ant-picker-cell-selected {
      &::before {
        color: #fff;
        background: #0068B2;
      }
    }

    // 控制伪元素所占宽度
    .ant-picker-cell {
      &::before {
        right: auto;
        left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
      }
    }

    // 隐藏季度选择器的按钮，用伪元素代替
    .ant-picker-cell-inner {
      display: none;
    }

    // 根据title更换相应伪元素的content，使其代替原本的按钮
    .ant-picker-cell[title$='-Q1'] {
      &::before {
        content: '第一季度';
      }
    }

    .ant-picker-cell[title$='-Q2'] {
      &::before {
        content: '第二季度';
      }
    }

    .ant-picker-cell[title$='-Q3'] {
      &::before {
        content: '第三季度';
      }
    }

    .ant-picker-cell[title$='-Q4'] {
      &::before {
        content: '第四季度';
      }
    }
  }

}

.adminPopover {
  padding-top: 16px;
  padding-left: 8px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    width: 240px;
    padding: 12px 0;

    .adminPopoverContent {
      max-height: 300px;
      width: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: auto;

      .adminPopoverItem {
        width: 100%;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.subjectScopeSelect {

  .ant-select-allow-clear .ant-select-selector,
  .ant-select-show-arrow .ant-select-selector {
    padding-right: 30px;
  }

  .ant-select-arrow {
    right: 2px
  }

  .ant-select-clear {
    right: 20px
  }
}