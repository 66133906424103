$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.recordItem {
    width: calc(50% - 20px);
    height: 170px;

    &:hover {
        // transition: transform .2s, box-shadow .2s;
        // transform: translateY(-4px);
        // box-shadow: 8px rgba(0, 0, 0, .1);
    }

    .recordItemHeader {
        height: 38px;
        width: 100%;
        padding: 0 24px;
        background: linear-gradient(133deg, #CEE4FD 0%, #EAF2FC 100%);
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #313131;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px 8px 0px 0px;
        cursor: default;

        .recordItemHeaderBtn {
            @include flex-c-c;
            cursor: pointer;
        }

        .recordItemHeaderBtnItem {
            @include flex-c-c;
            margin-left: 10px;
        }

        .recordItemHeaderBtnItem:first-of-type{
            margin-left: 0;
        }

        .deleteIcon {
            margin-right: 5px;

            div {
                height: 14px;
                width: 14px;
                @include flex-c-c;
            }

            svg {
                height: 14px;
                width: 14px;
            }

            path {
                fill: #313131;
            }
        }

        .recordItemHeaderBtnItem:hover {
            path {
                fill: #0068B2;
            }

            color: #0068B2;
        }
    }

    .recordItemCardContent {
        height: calc(100% - 38px);
        width: 100%;
        border: 1px solid #D8D8D8;
        border-top: none;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .recordItemCardContentItem {
            height: 60px;
            min-width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconContent {
                height: 50px;
                width: 50px;
                font-size: 26px;
                border-radius: 50%;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {

                    div,
                    svg {
                        height: 26px;
                        width: 26px;
                    }

                    div {
                        @include flex-c-c;
                    }

                    path {
                        fill: #fff;
                    }
                }
            }

            .textContent {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-left: 8px;
            }

            .num {
                font-size: 36px;
                height: 38px;
                line-height: 38px;
                font-family: Century Gothic-Regular, Century Gothic;
                font-weight: 400;
                color: #313131;
            }

            .view {
                font-size: 24px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
                cursor: pointer;
            }

            .text {
                flex: none;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #9E9E9E;
            }

            .result {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
                height: 38px;
                line-height: 38px;

                span {
                    font-size: 36px;
                    font-family: Century Gothic-Regular, Century Gothic;
                    font-weight: 400;
                    color: #FE545F;
                }

                .waitSvg {
                    width: 100%;
                    height: 100%;
                    @include flex-c-c;

                    div {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
    }

}

.rulesTooltip {
    width: 190px;
    z-index: 888;

    .ruleContent {
        padding: 5px;
        color: #FFFFFF;

        .ruleButtonBox {
            width: 100%;
            @include flex-c-c;
            margin-top: 8px;
        }

        .ruleButton {
            width: 138px;
            height: 32px;
            background: #16D8D8;
            border-color: #16D8D8;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }

        .ruleItem {
            display: flex;
        }

        .spot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #fff;
            margin-right: 6px;
            margin-top: 7px;
        }

        .title {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            line-height: 20px;
        }

        .text {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #DBDBDB;
            line-height: 20px;
        }
    }
}