$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.followContent {
    width: 100%;
    height: 100%;

    .noData {
        width: 100%;
        height: 100%;
        @include flex-c-c;
    }

    .iconBox {
        font-size: 18px;
        height: 26px;
        @include flex-c-c;
        .anticon {
            margin-left: 10px;
            cursor: pointer;
        }

        .iconSvg {
            height: 20px;
            width: 20px;
            margin: 0;

            svg,
            div {
                height: 20px;
                width: 20px;
            }
        }
        .iconSvg:hover{
            svg{
                fill: #006AB2;
            }
        }
    }

    .typeSreach {
        font-size: 12px;
        font-weight: normal;
        color: #9E9E9E;
        user-select: none;
        cursor: pointer;
        margin-top: 5px;

        &:hover {
            color: #006AB2;
        }
    }

    .headerTitleContent {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .ant-tabs-nav {
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    .cardBox {
        padding: 0 20px;
    }

    .headerTabPaneBox {
        position: absolute;
        left: 120px;
        top: 1px;
        display: flex;

        .headerTabPane {
            height: 24px;
            line-height: 24px;
            // width: 80px;
            margin-right: 24px;
            cursor: pointer;
            border-radius: 24px;
            padding: 0px 10px;
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #9E9E9E;
        }

        .headerTabPane:hover {
            background: #6CAEFF;
            color: #ffffff;
        }

        .activeHeaderTabPane {
            background: #6CAEFF;
            color: #ffffff;
        }
    }

    .loadingMoreDivider{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0;
        padding: 0 20px;
    }
}