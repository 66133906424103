$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.myCardContent {
    border-radius: 10px;
    padding: 15px;

    .infoBox {
        @include flex;

        .infoImg {
            display: block;
            width: 116px;
            height: 116px;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;

            &:hover {
                filter: brightness(105%);
            }
        }


        .hoverPic {
            user-select: none;
            cursor: pointer;
            color: #0068B2;
            border-radius: 4px;
            background-color: #F4F8FB;

            &:hover {
                background: rgba($color: #0068B2, $alpha: 0.1);
                color: #0068B2;
            }
        }

        .unHoverPic {
            cursor: unset !important;

            &:hover {
                background: #F4F8FB;
                color: #0068B2;
            }
        }

        .infoValue {
            width: calc(100% - 126px);
            margin-left: 10px;

            .titleBox {
                @include flex;
                @include flex-j-s-b;

                .title {
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                    user-select: none;

                    &:hover {
                        color: #0068B2;
                    }
                }

                .unTitle {
                    cursor: unset !important;

                    &:hover {
                        color: #000000;
                    }
                }

                .editTitle {
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                    user-select: none;
                    display: flex;

                    &:hover {
                        color: #0068B2;
                    }

                    .iconBox {
                        width: 60px;
                        @include flex-c-c;

                        .anticon {
                            margin-left: 6px;
                        }
                    }
                }

                .collectionIcon {
                    font-size: 16px;
                }
            }

            .itemBox {
                @include flex;
                flex-wrap: wrap;

                .valueItem {
                    @include flex;
                    margin-top: 8px;
                    flex-wrap: wrap;

                    .label {
                        width: 50px;
                        display: inline-block;
                        text-align: justify;
                        text-align-last: justify;
                        font-size: 12px;
                        font-weight: normal;
                        color: #9E9E9E;
                    }

                    .value {
                        width: calc(100% - 55px);
                        margin-left: 5px;
                        font-size: 12px;
                        font-weight: normal;
                        color: #535353;
                    }
                }
            }

            .progressBox {
                min-height: 20px;
                margin-top: 5px;
                display: flex;
                align-items: center;

                // background: red;
                .progress {
                    width: calc(100% - 150px);
                    display: flex;
                    cursor: pointer;

                    .progressItem {
                        height: 5px;
                        background-color: #BBBBBB;
                        margin-right: 3px;
                    }

                    .progressItem:last-of-type {
                        border-bottom-right-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    .progressItem:first-of-type {
                        border-bottom-left-radius: 5px;
                        border-top-left-radius: 5px;
                    }

                    .success {
                        background: #61D4AD;
                    }

                    @-webkit-keyframes candystripe {
                        to {
                            background-position: 10px 0;
                        }
                    }

                    @-moz-keyframes candystripe {
                        to {
                            background-position: 10px 0;
                        }
                    }

                    @keyframes candystripe {
                        to {
                            background-position: 10px 0;
                        }
                    }

                    .active {
                        background: #FEC054;
                        background-color: #FF9100FF;
                        /* box-shadow: 0 0 6px 2px rgba(255, 255, 255, 0.3) inset; */
                        background-image: linear-gradient(-45deg,
                                rgba(255, 83, 0, 1) 25%,
                                rgba(255, 255, 255, 0) 25%,
                                rgba(255, 255, 255, 0) 50%,
                                rgba(255, 83, 0, 1) 50%,
                                rgba(255, 83, 0, 1) 75%,
                                rgba(255, 255, 255, 0) 75%,
                                rgba(255, 255, 255, 0));
                        background-repeat: repeat-x;
                        background-size: 10px 10px;
                        -webkit-animation: candystripe 1s linear infinite;
                        -moz-animation: candystripe 1s linear infinite;
                        animation: candystripe 1s linear infinite;

                    }

                }

                .progressTag {
                    border-radius: 10px;
                    height: 20px;
                    font-size: 12px;
                    color: #FE545F;
                    border: 1px solid #FE545F;
                    padding: 0 5px;
                    margin-left: 10px;
                    flex: none;
                }

            }
        }

    }

    .ant-divider {
        margin: 15px 0;
    }

    .infoButtonBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btnBox {
            height: 32px;
            width: 100%;
            @include flex;
            @include flex-a-c;
            flex-direction: row-reverse;

            .ant-btn {
                margin-left: 10px;
            }
        }
    }
}

.tableColumnsParagraphTooltip {
    max-width: 450px;

    .tableColumnsParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}

.projectNameParagraphTooltip {
    max-width: 800px;

    .projectNameParagraphTip {
        max-height: 225px;
        width: 438px;
        font-size: 12px;
        // width: 200px;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}

.progressPopover {
    .ant-popover-inner {
        border-radius: 4px;
    }

    .ant-popover-inner-content {
        padding: 12px 0;
    }
}

.progressPopoverContent {
    display: flex;
    // align-items: center;
    justify-content: center;

    .itemContent {
        width: 220px;
        // margin-right: 16px;
        font-size: 12px;
        color: #787878;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 16px;
        border-right: 1px dashed #EEEEEE;

        .itemTitleBox {
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #313131;
        }

        .itemBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 4px;

            .itemProgressName {
                // width: calc(100% - 40px);
                width: 110px;
                margin-right: 6px;
                // text-align: right;
                display: inline-block;
                text-align: justify;
                text-align-last: justify;
            }

            .itemProgress {
                width: 40px;
            }
        }
    }

    .itemContent_尽调主体获取 {
        width: 180px !important;

        .itemProgressName {
            width: 96px !important;
        }
    }

    .itemContent_尽调主体经营与事件数据查询 {
        width: 220px !important;

        .itemProgressName {
            width: 96px !important;
        }
    }

    .itemContent_股权穿透计算 {
        width: 180px !important;

        .itemProgressName {
            width: 96px !important;
        }
    }

    .itemContent_关联方计算 {
        width: 190px !important;

        .itemProgressName {
            width: 108px !important;
        }
    }

    .itemContent_经营与事件数据查询 {
        width: 180px !important;
        border-right: none !important;

        .itemProgressName {
            width: 86px !important;
        }
    }
}